import usePaginate from '../hooks/usePaginate'
import useWarpgate from '../hooks/useWarpgate'
import Button from './Button'
import Card, { CardHeader } from './Card'
import DataTable from './DataTable'

const icon = (
  <svg
    className="w-4 h-4"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
    />
  </svg>
)

function RefreshButton(props) {
  return <Button {...props}>{icon}</Button>
}

function PaginateHeader({ title, paginate, query, data }) {
  return (
    <CardHeader
      title={title}
      subtitle={`Showing page ${paginate.page + 1}`}
      action={
        <div className="flex items-center space-x-1">
          <RefreshButton
            onClick={() => query.refetch()}
            disabled={query.loading}
          />
          <Button
            onClick={() =>
              paginate.setSort(
                paginate.sort === 'desc id' ? 'asc id' : 'desc id'
              )
            }
          >
            {paginate.sort === 'desc id' ? (
              <span>Newest first &darr;</span>
            ) : (
              <span>Oldest first &uarr;</span>
            )}
          </Button>
          <Button disabled={paginate.page === 0} onClick={paginate.onBack}>
            Prev
          </Button>
          <Button
            disabled={paginate.pageSize > data.length}
            onClick={paginate.onNext}
          >
            Next
          </Button>
        </div>
      }
    />
  )
}

export default function PaginateCard({
  title,
  paginate = {},
  query = {},
  data = {},
  columns = {},
  values = {},
  children,
}) {
  return (
    <Card>
      <PaginateHeader
        title={title}
        paginate={paginate}
        query={query}
        data={data}
      />
      {!children && (
        <DataTable
          columns={Object.keys(columns)}
          getLabel={(key) => columns[key]}
          getValue={(record, key) => values[key]?.(record) || record[key]}
          data={data}
        />
      )}

      {children}
    </Card>
  )
}

export const usePaginateCard = (QUERY) => {
  const paginate = usePaginate(30)
  const query = useWarpgate(QUERY, {
    paginate: paginate.variables,
    search: paginate.search,
  })

  return { paginate, query }
}
