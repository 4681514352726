import AppScreen from '../components/AppScreen'
import Button from '../components/Button'
import Chip from '../components/Chip'
import LocalDate from '../components/LocalDate'
import PaginateCard, { usePaginateCard } from '../components/PaginateCard'
import bytesToSize from '../utils/bytesToSize'

const columns = {
  name: 'File name',
  insertedAt: 'Date added',
  size: 'Size',
  type: 'Type',
  user: 'User',
  id: 'ID',
  action: '',
}

const dlIcon = (
  <svg
    className="text-purple-500 w-4 h-4"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
    />
  </svg>
)

const getColor = (type) => {
  if (type.indexOf('image') > -1) return 'yellow'
  return 'gray'
}

function Filename({ file }) {
  return (
    <a className="flex items-center space-x-2" href={file.url}>
      <span>{dlIcon}</span>
      <span className="text-md font-medium text-gray-900">{file.name}</span>
    </a>
  )
}

function CodeIcon() {
  return (
    <svg
      className="w-4 h-4"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"
      />
    </svg>
  )
}

export function FileChip({ type }) {
  return type && <Chip color={getColor(type)}>{type}</Chip>
}

const values = {
  name: (file) => <Filename file={file} />,
  insertedAt: (file) => <LocalDate date={file.insertedAt} />,
  type: ({ type }) => <FileChip type={type} />,
  url: (file) => <Button href={file.url}>{dlIcon}</Button>,
  user: (file) => file.user && file.user.username,
  size: ({ size }) => bytesToSize(size),
  action: ({ id }) => (
    <Button href={`/files/${id}`} size="sm">
      <CodeIcon />
    </Button>
  ),
}

const QUERY = `#graphql
  query FilesScreen($paginate: Paginate!) {
    tenant {
      id
      files(paginate: $paginate) {
        id
        url
        name
        size
        type
        caption
        uploaded
        user {
          id
          username
        }
        insertedAt
      }
    }
  }
`

export default function FilesScreen() {
  const { paginate, query } = usePaginateCard(QUERY)
  if (!query.data) return null

  return (
    <AppScreen
      action={
        <Button color="blue" to="/files/new">
          Add file
        </Button>
      }
    >
      <div className="p-6">
        <PaginateCard
          title="Files"
          paginate={paginate}
          query={query}
          data={query.data.tenant.files}
          columns={columns}
          values={values}
        />
      </div>
    </AppScreen>
  )
}
