import { Link } from 'wouter'
import AppScreen from '../components/AppScreen'
import Button from '../components/Button'
import PaginateCard, { usePaginateCard } from '../components/PaginateCard'

const columns = {
  id: 'ID',
  name: 'name',
  label: 'Label',
}

const values = {
  id: ({ id }) => <Link to={`/social/rooms/${id}`}>{id}</Link>,
  name: ({ id, name }) => (
    <Link to={`/social/rooms/${id}`} className="font-bold text-blue-500">
      {name}
    </Link>
  ),
}

const QUERY = `#graphql
  query RoomsScreen($paginate: Paginate!) {
    tenant {
      id
      rooms(paginate: $paginate) {
        id
        name
        label
      }
    }
  }
`

export default function RoomsScreen() {
  const { paginate, query } = usePaginateCard(QUERY)
  const data = query.data && query.data.tenant.rooms

  return (
    <AppScreen
      action={
        <Button color="blue" to="/social/rooms/new">
          New room
        </Button>
      }
    >
      <div className="p-6">
        <PaginateCard
          title="Rooms"
          paginate={paginate}
          query={query}
          data={data || []}
          columns={columns}
          values={values}
        />
      </div>
    </AppScreen>
  )
}
