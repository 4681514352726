import { Link } from 'wouter'
import Avatar from './Avatar'
import Chip from './Chip'
import DataTable from './DataTable'
import Money from './Money'
import ShopLink from './ShopLink'

const columns = {
  name: 'Product Name',
  active: 'Active',
  options: 'Options',
  shop: 'Shop',
  id: 'Product ID',
}

const colors = {
  true: 'green',
  false: 'red',
}

function ProductTitle({ product }) {
  const [file] = product.files
  const [price] = product.prices

  return (
    <Link
      to={`/commerce/products/${product.id}`}
      className="flex items-center space-x-4"
    >
      <Avatar src={file && file.url} round="md" size="lg" />

      <div>
        <div className="text-gray-800 font-medium">{product.name}</div>
        <div className="text-sm text-gray-400">
          <Money amount={price.amount} currency={price.currency} />
        </div>
      </div>
    </Link>
  )
}

const values = {
  name: (product) => <ProductTitle product={product} />,
  active: ({ active }) => <Chip color={colors[active]}>{String(active)}</Chip>,
  options: ({ options }) => (
    <div className="space-x-1">
      {options.map((name) => (
        <Chip>{name}</Chip>
      ))}
    </div>
  ),
  shop: ({ shop }) => <ShopLink shop={shop} />,
}

export default function ProductDataTable({ data = [] }) {
  return (
    <DataTable
      columns={Object.keys(columns)}
      data={data}
      getValue={(record, key) => values[key]?.(record) || values[key]}
      getLabel={(key) => columns[key]}
    />
  )
}

ProductDataTable.columns = columns
ProductDataTable.values = values

ProductDataTable.fragments = {
  product: `#graphql
    fragment ProductDataTable on Product {
      id
      name
      active
      options

      prices {
        id
        amount
        currency
      }

      files {
        id
        url
      }

      shop {
        id
        name
      }
    }
  `,
}
