import cn from 'classnames'
const userIcon = (
  <svg
    className="w-5 h-5"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
    />
  </svg>
)

const sizes = {
  sm: 'w-8 h-8',
  md: 'w-10 h-10',
  lg: 'w-12 h-12',
  xl: 'w-16 h-16',
}

const roundeds = {
  full: 'rounded-full',
  lg: 'rounded-lg',
  md: 'rounded-md',
  xl: 'rounded-xl',
}

export default function Avatar({ round = 'full', size = 'md', src }) {
  return (
    <div
      className={cn(
        sizes[size],
        roundeds[round],
        'text-gray-400 bg-gray-200 inline-flex items-center justify-center overflow-hidden'
      )}
    >
      {src ? <img src={src} alt="Avatar" /> : userIcon}
    </div>
  )
}
