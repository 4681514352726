import cn from 'classnames'

function TableHead(props) {
  const classnames = cn(
    'whitespace-nowrap px-4 py-3 text-left text-xs font-medium uppercase',
    'border-b border-gray-200 text-gray-500'
  )

  return <th className={classnames} {...props} />
}

function TableCell(props) {
  const classnames = cn(
    'whitespace-nowrap px-4 py-3 border-b border-gray-200 text-sm'
  )

  return <td className={classnames} {...props} />
}

export default function DataTable({
  data = [],
  columns = [],
  getLabel,
  getValue,
}) {
  const isEmpty = data.length === 0

  return (
    <div className="overflow-x-auto overflow-hidden">
      <table className="w-full border-collapse">
        <thead>
          <tr>
            {columns.map((column, key) => (
              <TableHead key={key}>{getLabel?.(column) || column}</TableHead>
            ))}
          </tr>
        </thead>

        {!isEmpty && (
          <tbody className="bg-white">
            {data.map((record, key) => (
              <tr key={key} className="hover:bg-gray-100">
                {columns.map((column, key) => (
                  <TableCell key={key}>
                    {getValue?.(record, column) || record[column]}
                  </TableCell>
                ))}
              </tr>
            ))}
          </tbody>
        )}
      </table>

      {isEmpty && (
        <div className="p-4 px-6 text-center select-none">
          <div classnames="text-gray-500">No records found.</div>
          <div className="text-sm text-gray-400">
            Data generated in your app will be displayed here.
          </div>
        </div>
      )}
    </div>
  )
}
