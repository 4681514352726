import Address from '../components/Address'
import AppScreen from '../components/AppScreen'
import Card from '../components/Card'
import Chip from '../components/Chip'
import DescriptionList from '../components/DescriptionList'
import LabelValue from '../components/LabelValue'
import ListItem from '../components/ListItem'
import ListSubheader from '../components/ListSubheader'
import LocalDate from '../components/LocalDate'
import Money from '../components/Money'
import useWarpgate from '../hooks/useWarpgate'

const QUERY = `#graphql
  query InvoiceScreen($invoiceId: ID!) {
    tenant {
      invoice(invoiceId: $invoiceId) {
        id
        tracking
        status
        currency
        subtotal
        discounts
        taxes
        total
        paid
        due

        billing {
          line_1
          line_2
          city
          state
          country
          zipCode
        }

        items {
          id
          name
          price
          qty
          total
          currency
        }

        period {
          startsAt
          endsAt
        }

        customer {
          id
          firstName
          lastName
          email
          phone
        }
      }
    }
  }
`

export default function InvoiceScreen({ params }) {
  const query = useWarpgate(QUERY, params)

  const { customer, items = [], period = {}, billing, ...invoice } = query.data
    ? query.data.tenant.invoice
    : {}

  return (
    <AppScreen title="Invoice">
      <div className="p-6 max-w-xl">
        <Card>
          <div>
            <div className="text-3xl font-medium">Invoice</div>
            <Chip>{invoice.tracking}</Chip>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-4">
              <div>
                <ListSubheader>Customer</ListSubheader>

                {customer && (
                  <div>
                    {customer.firstName} {customer.lastName}
                  </div>
                )}

                {!customer && <div className="text-gray-400">No customer</div>}
              </div>

              <div>
                <ListSubheader>Billing address</ListSubheader>

                {billing && <Address address={billing} />}

                {!billing && (
                  <div className="text-gray-400">No billing address</div>
                )}
              </div>
            </div>

            <div>
              <ListSubheader>Period</ListSubheader>
              <DescriptionList
                inline
                data={{
                  'Invoice Number': <Chip>{invoice.tracking}</Chip>,
                  'Start date': (
                    <LocalDate date={period.startsAt} format="MMM D, YYYY" />
                  ),
                  'End date': (
                    <LocalDate date={period.endsAt} format="MMM D, YYYY" />
                  ),
                }}
              />
            </div>
          </div>

          <div>
            <ListSubheader>Items</ListSubheader>
            <div className="border-b border-gray-300 mb-3">
              {items.map((item, key) => (
                <ListItem
                  key={key}
                  icon={`#${key + 1}`}
                  primary={`${item.name} x ${item.qty}`}
                  action={
                    <Money currency={item.currency} amount={item.total} />
                  }
                />
              ))}
            </div>

            <div className="divide-y divide-gray-300 divide-dashed ml-auto w-1/2">
              <LabelValue label="Sub-total">
                <Money amount={invoice.subtotal} />
              </LabelValue>

              <LabelValue label="Taxes">
                <Money amount={invoice.taxes} />
              </LabelValue>

              <LabelValue label="Discounts">
                <Money amount={invoice.discounts} />
              </LabelValue>

              <LabelValue label="Total">
                <Money amount={invoice.total} />
              </LabelValue>

              <LabelValue label="Paid">
                <Money amount={invoice.paid} />
              </LabelValue>

              <LabelValue label="Due">
                <div className="text-lg font-medium">
                  <Money amount={invoice.due} currency={invoice.currency} />
                </div>
              </LabelValue>
            </div>
          </div>
        </Card>
      </div>
    </AppScreen>
  )
}
