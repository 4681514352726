import cn from 'classnames'

export default function ListItem({
  icon,
  primary,
  secondary,
  action,
  onClick,
  selected,
  button,
}) {
  const classes = cn(
    'py-2 flex items-center',
    'transition duration-500',
    selected ? 'bg-gray-100' : 'bg-white',
    button && 'w-full text-left focus:outline-none focus:shadow-outline'
  )

  const Component = button ? 'button' : 'div'

  return (
    <Component className={classes} onClick={onClick}>
      {icon && <div className="mr-6 flex items-center">{icon}</div>}

      <div className="mr-auto">
        <div className="text-md font-medium text-gray-800">{primary}</div>
        <div className="text-sm text-gray-500">{secondary}</div>
      </div>

      {action}
    </Component>
  )
}
