import { useCallback, useEffect, useState } from "react"
import config from "../config"

class InputError extends Error {
  name = 'InputError'
  constructor(body) {
    super(body)
    this.body = body
  }
}

const parseError = err => {
  switch (err.name) {
    case 'InputError': return err.body
    default: return { message: err.message }
  }
}

const defaultOpts = {
  autoload: false,
  method: "GET",
}

export default function useAPI(path, opts = defaultOpts) {
  // Make sure the opts has the right default params
  opts = { ...defaultOpts, ...opts }

  const [state, setState] = useState({
    errors: {},
    loading: false,
    disabled: false,
    success: false,
    data: null,
    called: false
  })

  const call = useCallback(async body => {
    setState({
      errors: {},
      loading: true,
      disabled: true,
      success: false,
      called: true
    })

    try {
      const res = await fetch(config.apiHost + path, {
        method: opts.method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${config.token}`
        },
        body: JSON.stringify(body),
      })

      const data = await res.json()

      if (data.errors) {
        throw new InputError(data.errors)
      }

      setState({
        errors: {},
        loading: false,
        disabled: false,
        success: true,
        called: true,
        data
      })
    } catch (err) {
      setState({
        errors: parseError(err),
        loading: false,
        disabled: false,
        success: false,
        called: true,
      })
    }
  }, [opts.method, path])

  useEffect(() => {
    if (!opts.autoload) return
    if (state.called) return
    call()
  }, [call, opts.autoload, state.called])

  return [call, state]
}