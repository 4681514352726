import { Fragment } from 'react'
import SectionTitle from './SectionTitle'

export default function AppScreen({ title, action, children }) {
  return (
    <Fragment>
      <SectionTitle {...{ action }}>{title}</SectionTitle>
      {children}
    </Fragment>
  )
}
