import { Link } from 'wouter'
import AppScreen from '../components/AppScreen'
import Button from '../components/Button'
import PaginateCard, { usePaginateCard } from '../components/PaginateCard'
import { useWarpgateLazy } from '../hooks/useWarpgate'

const columns = {
  id: 'ID',
  name: 'Group name',
  action: 'Action',
}

const QUERY = `#graphql
  query GroupsScreen($paginate: Paginate!) {
    tenant {
      id
      groups(paginate: $paginate) {
        id
        name
      }
    }
  }
`

const DELETE_GROUP = `#graphql
  mutation DeleteGroup($groupId: ID!) {
    tenant {
      deleteGroup(groupId: $groupId) {
        id
      }
    }
  }
`

export default function GroupsScreen() {
  const [deleteGroup] = useWarpgateLazy(DELETE_GROUP)
  const { paginate, query } = usePaginateCard(QUERY)
  if (!query.data) return null

  const onDelete = async ({ id }) => {
    if (!window.confirm('Are you sure you want to delete?')) return
    await deleteGroup({ groupId: id })
    await query.refetch()
  }

  const values = {
    action: (group) => (
      <div className="space-x-3 text-right">
        <Button size="sm" color="red" onClick={() => onDelete(group)}>
          Delete
        </Button>
        <Button size="sm" to={`/groups/${group.id}/edit`}>
          Update
        </Button>
      </div>
    ),
    name: ({ id, name }) => <Link to={`/groups/${id}`}>{name}</Link>,
  }

  return (
    <AppScreen
      title="Groups"
      action={
        <Button color="blue" to="/groups/new">
          New group
        </Button>
      }
    >
      <div className="p-6">
        <PaginateCard
          title="Groups"
          paginate={paginate}
          query={query}
          data={query.data.tenant.groups}
          columns={columns}
          values={values}
        />
      </div>
    </AppScreen>
  )
}
