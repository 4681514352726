import cn from 'classnames'

const sizes = {
  sm: 'text-xs',
  md: 'text-sm',
}

const colors = {
  gray: 'bg-gray-100 border-gray-200 text-gray-800',
  green: 'bg-green-100 border-green-200 text-green-800',
  red: 'bg-red-100 border-red-200 text-red-800',
  blue: 'bg-blue-100 border-blue-200 text-blue-800',
  yellow: 'bg-yellow-100 border-yellow-200 text-yellow-800',
}

const padding = {
  sm: 'px-1',
  md: 'px-1.5',
}

export default function Chip({ size = 'md', color = 'gray', ...props }) {
  const classnames = cn(
    sizes[size],
    colors[color],
    padding[size],
    'border rounded inline-block select-none',
    'inline-flex items-center'
  )

  return <div {...props} className={classnames} />
}
