import { Link } from 'wouter'

import Avatar from '../components/Avatar'
import Card, { CardHeader } from '../components/Card'
import DescriptionList from '../components/DescriptionList'
import ListItem from '../components/ListItem'
import ListSubheader from '../components/ListSubheader'
import Money from '../components/Money'
import useWarpgate from '../hooks/useWarpgate'

const QUERY = `#graphql
  query OrderScreen($orderId: ID!) {
    tenant {
      order(orderId: $orderId) {
        id
        amount
        currency
        email
        tracking
        status
        insertedAt

        items {
          name
          description
          currency
          price
          qty
          total
          photoUrl
        }

        shop {
          id
          name
          tagline
          logoUrl
        }

        customer {
          id
          name
          firstName
          lastName
          email
          phone
        }

        shipping {
          line_1
          line_2
          city
          state
          zipCode
          country
        }
      }
    }
  }
`

export default function OrderScreen({ params }) {
  const query = useWarpgate(QUERY, params)

  const {
    items = [],
    shop,
    customer,
    shipping,
    __typename,
    ...order
  } = query.data ? query.data.tenant.order : {}

  return (
    <div className="p-6 max-w-lg space-y-4">
      <Card>
        <CardHeader title="Order information" />
        <DescriptionList data={order} size="sm" />

        <div className="pt-4 border-t border-gray-200">
          <ListSubheader>Items</ListSubheader>
          {items.map((item, key) => (
            <ListItem
              icon={<Avatar src={item.photoUrl} round="lg" size="xl" />}
              key={key}
              primary={item.name}
              secondary={item.description}
              action={
                <div>
                  <div className="text-xs text-gray-500">
                    <Money amount={item.price} />
                    <span> x {item.qty}</span>
                  </div>
                  <div className="text-md font-medium">
                    <Money amount={item.total} />
                  </div>
                </div>
              }
            />
          ))}

          <div className="py-3 flex items-center justify-end">
            <div className="text-right">
              <div className="text-lg font-bold">
                <Money amount={order.amount} currency={order.currency} />
              </div>
              <div className="text-sm text-gray-400">Total amount</div>
            </div>
          </div>
        </div>

        {customer && (
          <div className="pt-4 border-t border-gray-200">
            <ListSubheader>Customer</ListSubheader>
            <ListItem
              icon={<Avatar />}
              primary={customer.name}
              secondary={customer.email}
              action={customer.id}
            />
          </div>
        )}

        {customer && (
          <div className="pt-4 border-t border-gray-200">
            <ListSubheader>Shop</ListSubheader>
            <Link to={`/commerce/shops/${shop.id}`}>
              <ListItem
                icon={<Avatar round="lg" src={shop.logoUrl} />}
                primary={shop.name}
                secondary={shop.tagline}
              />
            </Link>
          </div>
        )}

        {shipping && (
          <div className="pt-4 border-t border-gray-200">
            <ListSubheader>Shipping address</ListSubheader>
            <div className="text-sm">
              <div>{shipping.line_1}</div>
              <div>{shipping.line_2}</div>
              <div>
                {shipping.city}, {shipping.state}
              </div>
              <div>
                {shipping.country}, {shipping.zipCode}
              </div>
            </div>
          </div>
        )}
      </Card>
    </div>
  )
}
