import { Link } from 'wouter'
import AppScreen from '../components/AppScreen'
import Button from '../components/Button'
import Chip from '../components/Chip'
import PaginateCard, { usePaginateCard } from '../components/PaginateCard'

const columns = {
  id: 'ID',
  title: 'Post title',
  slug: 'Slug',
  room: 'Room',
}

const values = {
  id: ({ id }) => <Link to={`/social/posts/${id}`}>{id}</Link>,
  title: (p) => <Link to={`/social/posts/${p.id}`}>{p.title}</Link>,
  room: ({ room }) =>
    room && (
      <Link to={`/social/rooms/${room.id}`}>
        <Chip>{room.name}</Chip>
      </Link>
    ),
}

const QUERY = `#graphql
  query PostsScreen($paginate: Paginate!) {
    tenant {
      id
      posts(paginate: $paginate) {
        id
        title
        slug
        insertedAt
        room {
          id
          name
        }
      }
    }
  }
`

export default function PostsScreen() {
  const { paginate, query } = usePaginateCard(QUERY)
  const data = query.data && query.data.tenant.posts

  return (
    <AppScreen
      action={
        <Button color="blue" to="/social/posts/new">
          New post
        </Button>
      }
    >
      <div className="p-6">
        <PaginateCard
          title="Posts"
          paginate={paginate}
          query={query}
          data={data || []}
          columns={columns}
          values={values}
        />
      </div>
    </AppScreen>
  )
}
