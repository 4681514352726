import config from '../config'
import { useOwner } from '../context/OwnerProvider'
import Button from './Button'

export default function AppHeader() {
  const { owner } = useOwner()
  const username = owner && owner.username

  return (
    <div className="text-gray-900 bg-white flex items-center px-6 h-16 border-b border-gray-200">
      <div className="ml-auto flex items-center space-x-4">
        <div>{username}</div>
        <Button
          startIcon={
            <svg
              className="w-4 h-4"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z"
                clipRule="evenodd"
              />
            </svg>
          }
          onClick={() => {
            window.localStorage.removeItem(config.jwtKey)
            window.location.href = '/login'
          }}
        >
          Logout
        </Button>
      </div>
    </div>
  )
}
