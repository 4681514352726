import { useLocation } from 'wouter'
import { useState } from 'react'
import AppScreen from '../components/AppScreen'
import Button from '../components/Button'
import PostFormCard from '../components/PostFormCard'
import useWarpgate from '../hooks/useWarpgate'
import warpgate from '../utils/warpgate'

const QUERY = `#graphql
  query EditPostScreen($postId: ID!) {
    tenant {
      post(postId: $postId) {
        ...PostFormCard
      }
    }
  }
  ${PostFormCard.fragments.post}
`

const UPDATE = `#graphql
  mutation EditPostScreen($postId: ID!, $input: PostInput!) {
    tenant {
      updatePost(postId: $postId, input: $input) {
        ...PostFormCard
      }
    }
  }
  ${PostFormCard.fragments.post}
`

export default function EditPostScreen({ params }) {
  const [, setLocation] = useLocation()
  const { data } = useWarpgate(QUERY, params)
  const [state, setState] = useState({})

  if (!data) return null

  const post = data.tenant.post
  const location = `/social/posts/${post.id}`

  const update = async (input) => {
    const { errors } = await warpgate(UPDATE, {
      postId: post.id,
      input,
    })

    if (errors) {
      setState({ errors })
    } else {
      setLocation(location)
    }
  }

  return (
    <AppScreen
      title="Edit post"
      action={<Button to={location}>Back to post</Button>}
    >
      <div className="p-6">
        <PostFormCard data={post} onSubmit={update} errors={state.errors} />
      </div>
    </AppScreen>
  )
}
