export default function Address({ address: a = {} }) {
  return (
    <div>
      <div>{a.line_1}</div>
      <div>{a.line_2}</div>
      <div>{a.city}</div>
      <div>{a.state}</div>
      <div>{a.zipCode}</div>
      <div>{a.country}</div>
    </div>
  )
}
