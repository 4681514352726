import { useRef, useState } from 'react'
import AppScreen from '../components/AppScreen'
import Button from '../components/Button'
import Card, { CardBody, CardHeader } from '../components/Card'
import LabelValue from '../components/LabelValue'
import TextField from '../components/TextField'
import useWarpgate from '../hooks/useWarpgate'
import bytesToSize from '../utils/bytesToSize'
import { FileChip } from './FilesScreen'

const QUERY = `#graphql

  query FileScreen($fileId: ID!) {
    tenant {
      file(fileId: $fileId) {
        id
        url
        type
        name
        size
      }
    }
  }
`

function CheckIcon() {
  return (
    <svg
      className="w-5 h-5 text-green-500"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M5 13l4 4L19 7"
      />
    </svg>
  )
}

function getTpl(type) {
  if (type.indexOf('image') > -1) {
    return '![$name]($url)'
  }

  return '[$name]($url)'
}

function FileCard({ file }) {
  const tpl = getTpl(file.type)
  const value = tpl.replace('$name', file.name).replace('$url', file.url)
  const ref = useRef()
  const [copied, setCopied] = useState(false)

  const copy = () => {
    setCopied(true)
  }

  return (
    <Card>
      <CardHeader title="File" />
      <CardBody>
        <div className="space-y-3">
          <LabelValue label="File name">{file.name}</LabelValue>
          <LabelValue label="File type">
            <FileChip type={file.type} />
          </LabelValue>
          <LabelValue label="File size">{bytesToSize(file.size)}</LabelValue>
          <TextField
            defaultValue={value}
            multiline
            rows={3}
            readOnly
            inputRef={ref}
            onClick={(e) => e.target.select()}
          />

          <Button
            color={copied ? 'white' : 'purple'}
            endIcon={copied && <CheckIcon />}
            onClick={copy}
          >
            Copy code
          </Button>
        </div>
      </CardBody>
    </Card>
  )
}

export default function FileScreen({ params: { fileId } }) {
  const { data } = useWarpgate(QUERY, { fileId })

  const file = data && data.tenant.file

  return (
    <AppScreen>
      <div className="p-6 max-w-lg">{file && <FileCard file={file} />}</div>
    </AppScreen>
  )
}
