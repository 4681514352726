import { useState } from 'react'
import AppScreen from '../components/AppScreen'
import Button from '../components/Button'
import Card, { CardHeader } from '../components/Card'
import LabelValue from '../components/LabelValue'
import ListItem from '../components/ListItem'
import UserSearchDialog from '../components/UserSearchDialog'
import useWarpgate, { useWarpgateLazy } from '../hooks/useWarpgate'

const QUERY = `#graphql
  query GroupScreen($groupId: ID!) {
    tenant {
      group(groupId: $groupId) {
        id
        name
        users {
          id
          username
        }
      }
    }
  }
`

const ADD_TO_GROUP = `#graphql
  mutation AddUserToGroup($userId: ID!, $groupId: ID!) {
    tenant {
      addToGroup(userId: $userId, groupId: $groupId) {
        id
      }
    }
  }
`

const REMOVE_FROM_GROUP = `#graphql
  mutation RemoveFromGroup($userId: ID!, $groupId: ID!) {
    tenant {
      removeFromGroup(userId: $userId, groupId: $groupId) {
        id
      }
    }
  }
`

export default function GroupScreen({ params }) {
  const { data, refetch } = useWarpgate(QUERY, params)
  const [addUserToGroup] = useWarpgateLazy(ADD_TO_GROUP, {
    onCompleted: () => refetch(),
  })

  const [removeFromGroup] = useWarpgateLazy(REMOVE_FROM_GROUP, {
    onCompleted: () => refetch(),
  })

  const group = data ? data.tenant.group : {}
  const users = group.users || []
  const [state, setState] = useState({})

  const open = () => setState({ ...state, adding: true })
  const close = () => setState({ ...state, adding: false })
  const select = async (user) => {
    close()
    addUserToGroup({
      userId: user.id,
      groupId: params.groupId,
    })
  }

  const remove = async (user, group) => {
    removeFromGroup({ userId: user.id, groupId: group.id })
  }

  return (
    <AppScreen
      title="Group"
      action={<Button to="/groups">Back to groups</Button>}
    >
      <div className="p-6 max-w-lg space-y-3">
        <Card>
          <CardHeader title="Group info" />
          <LabelValue label="ID">{group.id}</LabelValue>
          <LabelValue label="Group name">{group.name}</LabelValue>
        </Card>
        <Card>
          <CardHeader
            title="Users"
            action={<Button onClick={open}>Add user</Button>}
          />
          {users.map((user, key) => (
            <ListItem
              key={key}
              primary={user.username}
              action={
                <Button
                  color="red"
                  size="xs"
                  variant="flat"
                  onClick={() => remove(user, group)}
                >
                  Remove
                </Button>
              }
            />
          ))}
        </Card>
      </div>
      <UserSearchDialog open={state.adding} onClose={close} onSelect={select} />
    </AppScreen>
  )
}
