import { Redirect } from 'wouter'
import AppScreen from '../components/AppScreen'
import GroupFormCard from '../components/GroupFormCard'
import { useWarpgateLazy } from '../hooks/useWarpgate'

const CREATE_GROUP = `#graphql
  mutation NewGroupScreen($input: GroupInput!) {
    tenant {
      createGroup(input: $input) {
        id
        name
      }
    }
  }
`

export default function NewGroupScreen() {
  const [createGroup, { data, errors, loading }] = useWarpgateLazy(CREATE_GROUP)
  const submit = (input) => createGroup({ input })

  if (data) return <Redirect to="/groups" />

  return (
    <AppScreen>
      <div className="p-6 max-w-lg">
        <GroupFormCard onSubmit={submit} disabled={loading} errors={errors} />
      </div>
    </AppScreen>
  )
}
