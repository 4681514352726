import Avatar from '../components/Avatar'
import Card, { CardHeader } from '../components/Card'
import Chip from '../components/Chip'
import DataTable from '../components/DataTable'
import DescriptionList from '../components/DescriptionList'
import LocalDate from '../components/LocalDate'
import UserLink from '../components/UserLink'
import useWarpgate from '../hooks/useWarpgate'

const QUERY = `#graphql
  query ProductScreen($productId: ID!) {
    tenant {
      product(productId: $productId) {
        id
        name
        description
        active
        options
        insertedAt
        updatedAt

        shop {
          id
          name
          tagline
          logoUrl
          user {
            id
            username
          }
        }

        files {
          id
          url
          name
        }

        variants {
          id
          sku
          active
          attributes {
            name
            value
          }
        }
      }
    }
  }
`

const colors = {
  true: 'green',
  false: 'red',
}

const variantCols = {
  id: 'Variant ID',
  sku: 'SKU ID',
  active: 'Active',
  attributes: 'Attributes',
}

const variantValues = {
  active: ({ active }) => <Chip color={colors[active]}>{String(active)}</Chip>,
  attributes: ({ attributes }) => (
    <div className="space-x-1">
      {attributes.map((attrs, key) => (
        <div key={key}>
          {attrs.name}: {attrs.value}
        </div>
      ))}
    </div>
  ),
}

export default function ProductScreen({ params: { postId } }) {
  const query = useWarpgate(QUERY, { postId })

  const {
    __typename,
    variants = [],
    files = [],
    shop,
    options = [],
    ...product
  } = query.data ? query.data.tenant.product : {}

  return (
    <div className="p-6 space-y-4">
      <Card>
        <CardHeader title="Product details" />

        <div className="grid grid-cols-3 gap-4">
          <DescriptionList
            inline
            size="sm"
            data={{
              'Product name': product.name,
              'Added date': <LocalDate date={product.insertedAt} />,
              'Updated date': <LocalDate date={product.updatedAt} />,
              Active: (
                <Chip color={colors[product.active]}>
                  {String(product.active)}
                </Chip>
              ),
              Description: product.description,
              'Product options': (
                <div className="space-x-1">
                  {options.map((name) => (
                    <Chip key={name}>{name}</Chip>
                  ))}
                </div>
              ),
            }}
          />
        </div>
      </Card>

      {shop && (
        <Card>
          <CardHeader
            title={shop.name}
            subtitle={shop.tagline}
            avatar={<Avatar size="lg" src={shop.logoUrl} />}
          />
          <div className="w-1/3">
            <DescriptionList
              inline
              data={{
                'Shop ID': shop.id,
                'Shop name': shop.name,
                Tagline: shop.tagline,
                Owner: <UserLink user={shop.user} />,
              }}
            />
          </div>
        </Card>
      )}

      {variants.length > 0 && (
        <Card>
          <CardHeader title="Variants" />
          <DataTable
            columns={Object.keys(variantCols)}
            data={variants}
            getValue={(record, key) =>
              variantValues[key]?.(record) || record[key]
            }
          />
        </Card>
      )}

      {files.length > 0 && (
        <Card>
          <CardHeader title="Files" />
          <div className="grid grid-cols-4 gap-4">
            {files.map((file) => (
              <div key={file.id}>
                <img src={file.url} alt={file.name} />
              </div>
            ))}
          </div>
        </Card>
      )}
    </div>
  )
}
