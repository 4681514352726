import { useEffect } from 'react'
import { useRoute } from 'wouter'
import Button from '../components/Button'
import Card, { CardActions, CardBody, CardHeader } from '../components/Card'
import Modal from '../components/Modal'
import useAPI from '../hooks/useAPI'

export default function VerifyScreen() {
  const { code } = useRoute()
  const [verify, { called, errors, success, loading }] = useAPI('/verify', {
    method: 'DELETE',
  })

  useEffect(() => {
    if (called) return
    verify({ code })
  }, [called, code, verify])

  if (loading) return 'Verifying...'

  return (
    <Modal>
      <div className="max-w-md">
        <Card>
          <CardHeader title="Account verification" />
          <CardBody>
            <div className="text-center">
              {success && (
                <div className="inline-flex items-center">
                  <div className="mr-2 text-green-500">
                    <svg
                      className="w-6 h-6"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>

                  <div>{'Email sucessfully verified!'}</div>
                </div>
              )}

              {errors.code && (
                <div className="inline-flex items-center mx-auto">
                  <div className="mr-2">{'😵'}</div>
                  <div>{'It seems that the code is invalid'}</div>
                </div>
              )}
            </div>
          </CardBody>

          <CardActions>
            <Button to="/home">{'Got to home'} &rarr;</Button>
          </CardActions>
        </Card>
      </div>
    </Modal>
  )
}
