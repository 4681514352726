import { useEffect } from 'react'
import config from '../config'

export default function LogoutScreen() {
  useEffect(() => {
    window.localStorage.removeItem(config.jwtKey)
    window.location.href = '/login'
  }, [])

  return (
    <div className="flex items-center justify-center fixed inset-0">
      <div className="font-medium text-xl">Logging out...</div>
    </div>
  )
}
