import { Link } from 'wouter'
import Chip from '../components/Chip'
import LocalDate from '../components/LocalDate'
import Money from '../components/Money'
import PaginateCard, { usePaginateCard } from '../components/PaginateCard'

const columns = {
  date: 'Date added',
  time: 'Time',
  tracking: 'Tracking ID',
  status: 'Status',
  total: 'Total due',
  email: 'Email',
  id: 'Order ID',
}

const colors = {
  cancelled: 'red',
  delivered: 'green',
  in_transit: 'yellow',
  pickup_available: 'yellow',
  payment_due: 'red',
  processing: 'blue',
}

const values = {
  tracking: ({ id, tracking }) => (
    <Link to={`/commerce/orders/${id}`}>
      <Chip>{tracking}</Chip>
    </Link>
  ),
  date: ({ insertedAt }) => (
    <LocalDate date={insertedAt} format="MMM DD, YYYY" />
  ),
  time: ({ insertedAt }) => <LocalDate date={insertedAt} format="h:mm A" />,
  total: ({ currency, amount }) => (
    <Money currency={currency} amount={amount} />
  ),
  status: ({ status }) => <Chip color={colors[status]}>{status}</Chip>,
}

const QUERY = `#graphql
  query OrdersScreen($paginate: Paginate!) {
    tenant {
      id
      orders(paginate: $paginate) {
        id
        tracking
        insertedAt
        amount
        currency
        email
        status
      }
    }
  }
`

export default function OrdersScreen() {
  const { paginate, query } = usePaginateCard(QUERY)
  const data = query.data && query.data.tenant.orders

  return (
    <div className="p-6">
      <PaginateCard
        title="Orders"
        paginate={paginate}
        query={query}
        data={data || []}
        columns={columns}
        values={values}
      />
    </div>
  )
}
