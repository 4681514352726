import Chip from '../components/Chip'
import LocalDate from '../components/LocalDate'
import Money from '../components/Money'
import PaginateCard, { usePaginateCard } from '../components/PaginateCard'

const QUERY = `#graphql
  query LedgerEntriesScreen($paginate: Paginate!) {
    tenant {
      entries(paginate: $paginate) {
        id
        insertedAt
        currency
        type
        note
        tracking
        amounts {
          id
          debit
          credit
          amount
          account {
            id
            currency
            name
            type
            user {
              id
              username
            }
          }
        }
      }
    }
  }
`

const columns = {
  id: 'ID',
  insertedAt: 'Date',
  tracking: 'Tracking ID',
  type: 'Type',
  currency: 'Currency',
  amounts: 'Amounts',
}

const colors = {
  withdrawal: 'yellow',
  transfer: 'blue',
  deposit: 'green',
  asset: 'blue',
  liability: 'yellow',
  revenue: 'green',
}

function AmountsColumn({ amounts }) {
  amounts = amounts.map((amount) => ({
    ...amount,
    account: {
      ...amount.account,
      type: amount.account.type.toLowerCase(),
    },
  }))

  return (
    <div className="divide-y divide-gray-200">
      {amounts.map((amount, key) => (
        <div key={key} className="flex items-center py-1">
          <div className="flex space-x-2">
            <div>{amount.account.name}</div>
            <Chip size="sm" color={colors[amount.account.type]}>
              {amount.account.type}
            </Chip>
          </div>
          <div className="ml-auto">
            <Money amount={amount.amount} format="0,0.000000" />
          </div>
        </div>
      ))}
    </div>
  )
}

const values = {
  type: ({ type }) => <Chip color={colors[type]}>{type}</Chip>,
  tracking: ({ tracking }) => <Chip color="blue">{tracking}</Chip>,
  insertedAt: ({ insertedAt }) => <LocalDate date={insertedAt} />,
  amounts: ({ amounts }) => <AmountsColumn amounts={amounts} />,
}

export default function LedgerEntriesScreen() {
  const { paginate, query } = usePaginateCard(QUERY)

  if (!query.data) return null

  return (
    <div className="p-6">
      <PaginateCard
        title="Ledger Entries"
        paginate={paginate}
        query={query}
        data={query.data.tenant.entries}
        columns={columns}
        values={values}
      />
    </div>
  )
}
