import { InputLabel } from './TextField'

export default function Select({
  label,
  options = [],
  getOptionLabel,
  inputRef,
  onChange,
  name,
  defaultValue,
}) {
  const handleChange = onChange && ((e) => onChange(e && e.target.value))

  return (
    <div className="relative space-y-1">
      <InputLabel label={label} />
      <div className="border border-gray-300 relative overflow-hidden rounded-lg p-1">
        <select
          className="w-full py-1 px-2 appearance-none rounded-lg"
          name={name}
          ref={inputRef}
          onChange={handleChange}
          value={defaultValue}
        >
          <option></option>
          {options.map((value, key) => (
            <option key={key} value={value}>
              {getOptionLabel?.(value) || value}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}
