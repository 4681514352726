import { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Button from '../../components/Button'
import TextField from '../../components/TextField'
import sanitizeString from '../../utils/santizeString'

export default function RegisterForm({ errors = {}, disabled, onSubmit }) {
  const { t } = useTranslation()
  const { register, handleSubmit } = useForm()
  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')
  const [agreeTos] = useState(true)

  const formatUsername = useCallback((e) => {
    setUsername(sanitizeString(e.target.value))
  }, [])

  const formatEmail = useCallback((e) => {
    setEmail(e.target.value.trim().toLowerCase())
  }, [])

  const submit = (input) =>
    onSubmit({
      ...input,
      username,
      email,
      agreeTos,
    })

  return (
    <form className="space-y-4" onSubmit={handleSubmit(submit)}>
      <div className="text-xs uppercase font-medium tracking-wider text-gray-500">
        Customer information
      </div>

      <div className="space-y-2">
        <div className="grid grid-cols-2 gap-2">
          <TextField
            name="first_name"
            placeholder="First name"
            inputRef={register}
            error={t(errors.first_name)}
            disabled={disabled}
          />
          <TextField
            name="last_name"
            placeholder="Last name"
            inputRef={register}
            error={t(errors.last_name)}
            disabled={disabled}
          />
        </div>

        <TextField
          value={email}
          onChange={formatEmail}
          placeholder="Your e-mail"
          startIcon={
            <svg
              className="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
              />
            </svg>
          }
          error={t(errors.email)}
          disabled={disabled}
        />
      </div>

      <div className="text-xs uppercase font-medium tracking-wider text-gray-500">
        Login credentials
      </div>

      <div className="space-y-2">
        <TextField
          placeholder="Username"
          value={username}
          onChange={formatUsername}
          startIcon={
            <svg
              className="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
          }
          error={t(errors.username)}
          disabled={disabled}
        />

        <TextField
          name="password"
          placeholder="Choose a password"
          inputRef={register}
          startIcon={
            <svg
              className="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
              />
            </svg>
          }
          type="password"
          error={t(errors.password)}
          disabled={disabled}
        />
      </div>

      {/* <div className="text-gray-500 text-sm">
        <label className="space-x-3 items-center flex select-none">
          <span>
            <input
              type="checkbox"
              value={agreeTos}
              onChange={(e) => setAgreeTos(e.target.checked)}
            />
          </span>

          <span className="space-x-1">
            <span>{'I agree with the'}</span>

            <a
              href={'https://docs.warpgate.tech/legal/terms-of-service'}
              target="_blank"
              rel="noreferrer"
              className="underline"
            >
              {'terms of service'}
            </a>
          </span>
        </label>
      </div> */}

      <Button
        type="submit"
        disabled={!agreeTos || disabled}
        fullWidth
        size="md"
        color="purple"
      >
        {'Create my account'}
      </Button>
    </form>
  )
}
