import { useLocation } from 'wouter'
import { useEffect, useState } from 'react'
import AppScreen from '../components/AppScreen'
import Button from '../components/Button'
import PostCard from '../components/PostCard'
import useWarpgate from '../hooks/useWarpgate'
import warpgate from '../utils/warpgate'

const QUERY = `#graphql
  query PostScreen($postId: ID!) {
    tenant {
      post(postId: $postId) {
        ...PostCard
      }
    }
  }
  ${PostCard.fragments.post}
`

const DELETE = `#graphql
  mutation PostScreen($postId: ID!) {
    tenant {
      deletePost(postId: $postId) {
        ...PostCard
      }
    }
  }
  ${PostCard.fragments.post}
`

export default function PostScreen({ params }) {
  const [, setLocation] = useLocation()
  const query = useWarpgate(QUERY, params)
  const [post, setPost] = useState({})

  useEffect(() => {
    if (query.data) {
      setPost(query.data.tenant.post)
    }
  }, [query.data])

  const location = `/social/posts/${post.id}`

  const confirmDelete = async () => {
    if (!window.confirm('Are you sure you want to delete this post?')) return
    await warpgate(DELETE, params)
    setPost({})
    setLocation('/social/posts')
  }

  return (
    <AppScreen
      title="Post"
      action={
        <div className="space-x-2">
          <Button to={location + '/change_room'}>Change room</Button>
          <Button to={location + '/edit'}>Edit post</Button>
        </div>
      }
    >
      <div className="p-6 max-w-screen-md space-y-3">
        <PostCard post={post} />
        <div className="text-right">
          <Button size="sm" onClick={() => confirmDelete()}>
            Delete post
          </Button>
        </div>
      </div>
    </AppScreen>
  )
}
