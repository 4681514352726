import { Link } from 'wouter'
import Chip from '../components/Chip'
import LocalDate from '../components/LocalDate'
import Money from '../components/Money'
import PaginateCard, { usePaginateCard } from '../components/PaginateCard'

const columns = {
  id: 'ID',
  tracking: 'Tracking ID',
  currency: 'Currency',
  subtotal: 'Subtotal',
  discounts: 'Discounts',
  taxes: 'Taxes',
  total: 'total',
  startsAt: 'Starts at',
  endsAt: 'Ends at',
}

const colors = {
  cancelled: 'red',
  delivered: 'green',
  in_transit: 'yellow',
  pickup_available: 'yellow',
  payment_due: 'red',
  processing: 'blue',
}

const values = {
  tracking: ({ id, tracking }) => (
    <Link to={`/commerce/invoices/${id}`}>
      <Chip>{tracking}</Chip>
    </Link>
  ),
  currency: ({ currency }) => <Chip color="green">{currency}</Chip>,
  startsAt: ({ period }) => (
    <LocalDate date={period.startsAt} format="MMM DD, YYYY" />
  ),
  endsAt: ({ period }) => (
    <LocalDate date={period.endsAt} format="MMM DD, YYYY" />
  ),
  status: ({ status }) => <Chip color={colors[status]}>{status}</Chip>,
  subtotal: ({ subtotal }) => <Money amount={subtotal} />,
  discounts: ({ discounts: amount }) => <Money amount={amount} />,
  taxes: ({ taxes: amount }) => <Money amount={amount} />,
  total: ({ total: amount }) => <Money amount={amount} />,
}

const QUERY = `#graphql
  query InvoicesScreen($paginate: Paginate!) {
    tenant {
      id
      invoices(paginate: $paginate) {
        id
        tracking
        currency
        subtotal
        taxes
        discounts
        total
        period {
          startsAt
          endsAt
        }
      }
    }
  }
`

export default function InvoicesScreen() {
  const { paginate, query } = usePaginateCard(QUERY)
  const data = query.data && query.data.tenant.invoices

  return (
    <div className="p-6">
      <PaginateCard
        title="Invoices"
        paginate={paginate}
        query={query}
        data={data || []}
        columns={columns}
        values={values}
      />
    </div>
  )
}
