import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useWarpgateLazy } from '../hooks/useWarpgate'
import Button from './Button'
import Card, { CardActions, CardBody, CardHeader } from './Card'
import Dialog from './Dialog'
import ListItem from './ListItem'
import TextField from './TextField'

const SEARCH = `#graphql
  query UserSearch($keyword: String!) {
    tenant {
      searchUser(keyword: $keyword) {
        id
        username
      }
    }
  }
`

export default function UserSearchDialog({ open, onSelect, onClose }) {
  const [searchUser, { data }] = useWarpgateLazy(SEARCH)
  const { register, handleSubmit } = useForm()
  const [users, setUsers] = useState()

  useEffect(() => {
    if (data) {
      setUsers(data.tenant.searchUser)
    }
  }, [data])

  const submit = (input) => {
    searchUser(input)
  }

  return (
    <Dialog {...{ open, onClose }}>
      <form onSubmit={handleSubmit(submit)}>
        <Card>
          <CardHeader title="Find user" />
          <CardBody>
            <TextField
              name="keyword"
              placeholder="Username"
              inputRef={register}
              autoComplete="off"
            />
            {users && users.length === 0 && (
              <div className="text-center text-gray-500">No users found</div>
            )}
            {(users || []).map((user, key) => (
              <ListItem
                key={key}
                primary={user.username}
                action={
                  <Button
                    size="sm"
                    variant="flat"
                    color="blue"
                    onClick={() => onSelect?.(user)}
                  >
                    Add
                  </Button>
                }
              />
            ))}
          </CardBody>
          <CardActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button color="blue" type="submit">
              Search
            </Button>
          </CardActions>
        </Card>
      </form>
    </Dialog>
  )
}
