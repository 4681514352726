import { useMemo } from 'react'
import { useState } from 'react'
import { Redirect } from 'wouter'
import Card, { CardHeader } from '../components/Card'

import PostFormCard from '../components/PostFormCard'
import Select from '../components/Select'
import useWarpgate from '../hooks/useWarpgate'
import warpgate from '../utils/warpgate'

const CREATE_POST = `#graphql
  mutation createPost($roomId: ID, $input: PostInput!) {
    tenant {
      createPost(roomId: $roomId, input: $input) {
        id
        title
        body
        insertedAt
      }
    }
  }
`

const QUERY = `#graphql
  query NewPostScreen {
    tenant {
      rooms {
        id
        name
      }
    }
  }
`

const defaultState = {
  errors: {},
  loading: false,
}

const mapToIds = (records) =>
  records.reduce((prev, f) => {
    return { ...prev, [f.id]: f }
  }, {})

export default function NewPostScreen({ params }) {
  const [roomId, setRoomId] = useState(params.roomId)
  const query = useWarpgate(QUERY, params)
  const [post, setPost] = useState({})

  const [{ loading, errors }, setState] = useState(defaultState)

  const roomIds = useMemo(() => {
    if (!query.data) return {}
    return mapToIds(query.data.tenant.rooms)
  }, [query.data])

  const create = async (input) => {
    const params = roomId ? { roomId, input } : { input }
    const { data, errors } = await warpgate(CREATE_POST, params)

    setState({ errors })

    if (!errors) {
      setPost(data.tenant.createPost)
    }
  }

  if (post.id) {
    return <Redirect to={`/social/posts/${post.id}`} />
  }

  return (
    <div className="p-6 space-y-3">
      <Card>
        <CardHeader title="Select room" />
        <Select
          label="Room"
          options={Object.keys(roomIds)}
          getOptionLabel={(id) => roomIds[id].name}
          onChange={setRoomId}
          defaultValue={params.roomId}
        />
      </Card>

      <PostFormCard onSubmit={create} errors={errors} disabled={loading} />
    </div>
  )
}
