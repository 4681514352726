import Modal from './Modal'

export default function Dialog({ open, onClose, children }) {
  return (
    <Modal {...{ open, onClose }}>
      <div className="p-6 bg-gray-200 bg-opacity-50 inset-0 absolute flex items-center justify-center">
        <div className="w-full max-w-sm">{children}</div>
      </div>
    </Modal>
  )
}
