import { useForm } from 'react-hook-form'
import { useRoute } from 'wouter'
import Button from '../components/Button'
import Card, { CardActions, CardBody, CardHeader } from '../components/Card'
import Dialog from '../components/Dialog'
import TextField from '../components/TextField'
import useAPI from '../hooks/useAPI'

const lockIcon = (
  <svg
    className="w-6 h-6"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
    />
  </svg>
)

export default function ClaimPasswordResetScreen() {
  const { code } = useRoute()
  const { handleSubmit, register } = useForm()
  const [
    resetPassword,
    { success, errors, loading },
  ] = useAPI('/password_reset/' + code, { method: 'DELETE' })

  const submit = (input) => {
    resetPassword(input)
  }

  return (
    <Dialog open>
      <form className="p-6 max-w-lg" onSubmit={handleSubmit(submit)}>
        <Card>
          <CardHeader title="Password reset" />
          {success && (
            <CardBody>
              <div className="text-center space-y-3">
                <div className="text-xl font-medium">
                  {'Password succesfully updated!'}
                </div>
                <div className="text-sm text-gray-500">
                  {'You can now login using your new password.'}
                </div>
              </div>
            </CardBody>
          )}

          {!success && (
            <CardBody>
              <div className="space-y-3">
                <TextField
                  name="password"
                  label="Password"
                  type="password"
                  placeholder="Create a new secure password"
                  inputRef={register}
                  error={errors.password}
                  disabled={loading}
                  startIcon={lockIcon}
                />
                <TextField
                  name="new_password"
                  label="Confirm password"
                  type="password"
                  placeholder="Confirm new password"
                  inputRef={register}
                  error={errors.new_password}
                  disabled={loading}
                  startIcon={lockIcon}
                />
              </div>
            </CardBody>
          )}
          <CardActions>
            {!success && (
              <Button type="submit" color="blue" size="md" disabled={loading}>
                Reset password
              </Button>
            )}

            {success && (
              <Button to="/login" size="md">
                Go to login &rarr;
              </Button>
            )}
          </CardActions>
        </Card>
      </form>
    </Dialog>
  )
}
