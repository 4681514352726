import cn from 'classnames'
import { useMemo } from 'react'
import { Link } from 'wouter'

const padding = {
  xs: 'py-1 px-2',
  sm: 'py-2 px-3',
  md: 'py-2 px-4',
  lg: 'py-3 px-4',
  xl: 'py-3 px-4',
}

const containedColors = {
  purple: 'bg-purple-500 focus:bg-purple-800 border-purple-500 text-purple-100',
  blue: 'bg-blue-500 focus:bg-blue-800 border-blue-500 text-blue-100',
  red: 'bg-red-500 focus:bg-red-800 border-red-500 text-red-100',
  green: 'bg-green-500 focus:bg-green-800 border-green-500 text-green-100',
  undefined: 'focus:bg-gray-100 bg-white-100 text-gray-700 border-gray-300',
}

const flatColors = {
  purple: 'focus:bg-purple-200 bg-purple-100 text-purple-800 border-purple-100',
  blue: 'focus:bg-blue-200 bg-blue-100 text-blue-800 border-blue-100',
  red: 'focus:bg-red-200 bg-red-100 text-red-800 border-red-100',
  green: 'focus:bg-green-200 bg-green-100 text-green-800 border-green-100',
}

const radius = {
  md: 'rounded-md',
  lg: 'rounded-lg',
  xl: 'rounded-xl',
  full: 'rounded-full',
}

const sizes = {
  xs: 'text-xs',
  sm: 'text-sm',
  md: 'text-md',
  xl: 'text-xl',
}

const shadows = {
  md: 'shadow-md',
  lg: 'shadow-lg',
}

export default function Button({
  children,
  color,
  disabled,
  fullWidth,
  shadow = 'sm',
  size = 'md',
  to,
  href,
  type = 'button',
  variant = 'contained',
  startIcon,
  rounded = 'md',
  endIcon,
  ...props
}) {
  const [contained, flat] = [variant === 'contained', variant === 'flat']

  const classnames = cn(
    flat && flatColors[color],
    contained && containedColors[color],
    contained && shadows[shadow],
    radius[rounded],
    sizes[size],
    padding[size],
    fullWidth && 'w-full',
    disabled && 'opacity-50 pointer-events-none',
    'border',
    'focus:outline-none focus:ring',
    'transition duration-200',
    'select-none',
    'inline-flex items-center',
    'leading-tight',
    'appearance-none'
  )

  const Component = useMemo(() => {
    if (to) return Link
    if (href) return 'a'
    return 'button'
  }, [href, to])

  return (
    <Component {...props} {...{ to, href, type }} className={classnames}>
      {startIcon && <span className="mr-2">{startIcon}</span>}
      <span className="mx-auto">{children}</span>
      {endIcon && <span className="ml-2">{endIcon}</span>}
    </Component>
  )
}
