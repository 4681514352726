import Button from '../components/Button'
import Card, { CardBody, CardHeader } from '../components/Card'
import useAPI from '../hooks/useAPI'
import cn from 'classnames'
import SectionTitle from '../components/SectionTitle'
import { useWarpgateLazy } from '../hooks/useWarpgate'

function CreateButton({ disabled, ...props }) {
  const iconClass = cn(disabled && 'animate animate-spin')

  return (
    <Button
      size="md"
      color="purple"
      shadow="lg"
      startIcon={
        <span className={iconClass}>
          <svg
            className="w-5 h-5 text-purple-100"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
            />
          </svg>
        </span>
      }
      disabled={disabled}
      {...props}
    />
  )
}

function CreateState({ owner = {} }) {
  const [createTenant, { success, disabled }] = useAPI('/api/tenant', {
    method: 'POST',
  })

  const title = success
    ? '✨ Successfully initialized database!'
    : 'Welcome to your account!'

  const subtitle = success
    ? 'Head over to the developer docs to generate your API keys.'
    : "To get started, let's initialize your database."

  return (
    <div className="space-y-8">
      <div>
        <div className="text-lg">{title}</div>
        <div className="text-gray-500">{subtitle}</div>
      </div>

      <div className="text-right">
        {success && (
          <Button size="md" shadow="lg" color="green" to="/developers">
            Go to developers &rarr;
          </Button>
        )}
        {!success && (
          <CreateButton onClick={() => createTenant()} disabled={disabled}>
            {disabled
              ? `Creating ${owner.username}`
              : `Create ${owner.username}`}
          </CreateButton>
        )}
      </div>
    </div>
  )
}

const QUERY = `#graphql
  query {
    owner {
      id
      username
    }

    tenant {
      id
    }
  }
`

export default function SetupScreen() {
  const { data } = useWarpgateLazy(QUERY)

  return (
    <>
      <SectionTitle>Setup</SectionTitle>
      <div className="p-6 max-w-lg">
        <Card>
          <CardHeader
            avatar={
              <svg
                className="w-6 h-6 text-purple-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            }
            title="Initialize database"
          />
          <CardBody>
            <CreateState owner={data && data.owner} />
          </CardBody>
        </Card>
      </div>
    </>
  )
}
