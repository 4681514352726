import { useMemo } from 'react'
import { Redirect } from 'wouter'
import AccountUsage from './HomeScreen/AccountUsage'
import AppScreen from '../components/AppScreen'
import Button from '../components/Button'
import Card from '../components/Card'
import useAPI from '../hooks/useAPI'
import useWarpgate from '../hooks/useWarpgate'

function TaskItem({ icon, action, primary }) {
  return (
    <div className="flex items-center">
      {icon && <span className="mr-3">{icon}</span>}

      <span className="mr-auto">{primary}</span>

      {action && <div className="ml-3">{action}</div>}
    </div>
  )
}

function ResendLinkButton() {
  const [
    resendLink,
    { called, loading },
  ] = useAPI('/api/user/verification_link', { method: 'POST' })

  const label = useMemo(() => {
    if (called) return 'Link sent'
    if (loading) return 'Resending...'
    return 'Resend link'
  }, [called, loading])

  const submit = () => resendLink()

  return (
    <Button disabled={called} onClick={submit}>
      {label}
    </Button>
  )
}

const checkIcon = (
  <svg
    className="w-6 h-6 text-green-500"
    fill="currentColor"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
      clipRule="evenodd"
    />
  </svg>
)
const removeIcon = (
  <svg
    className="w-6 h-6 text-gray-300"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
    />
  </svg>
)

function AccountActionItems({ owner }) {
  const verified = Boolean(owner.verifiedAt)

  if (!owner.email) return null

  return (
    <Card>
      <div className="px-6 py-3">
        <TaskItem
          icon={verified ? checkIcon : removeIcon}
          action={!verified && <ResendLinkButton />}
          primary={verified ? 'Email is verified' : 'Email needs verification'}
        />
      </div>
    </Card>
  )
}

const QUERY = `#graphql
  query HomeScreen {
    owner {
      id
      username
      verifiedAt
    }

    tenant {
      id
      ...AccountUsage
    }
  }

  ${AccountUsage.fragments.tenant}
`

export default function HomeScreen() {
  const { loading, data } = useWarpgate(QUERY)
  const tenant = data ? data.tenant : {}
  const owner = data ? data.owner : {}

  if (loading) return null
  if (!tenant) return <Redirect to="/setup" />

  return (
    <AppScreen title={`Welcome, ${owner.username}`}>
      <div className="p-6 max-w-lg space-y-4">
        <AccountUsage tenant={tenant} />
        <AccountActionItems owner={owner} />
      </div>
    </AppScreen>
  )
}
