import Button from '../components/Button'
import Card, { CardBody, CardHeader } from '../components/Card'
import DescriptionList from '../components/DescriptionList'
import SectionTitle from '../components/SectionTitle'

import config from '../config'
import useAPI from '../hooks/useAPI'

const developerDetails = {
  'GraphQL API Endpoint': config.graphQLHost,
  'GraphiQL Playground': config.apiHost + '/graphiql',
  'API Docs': (
    <a href="https://docs.warpgate.tech" target="_blank" rel="noreferrer">
      {'https://docs.warpgate.tech'}
    </a>
  ),
}

function DeleteAPITokenButton({ onDelete, apiToken }) {
  const [deleteApiToken] = useAPI(`/api/api_tokens/${apiToken}`, { method: 'DELETE' })

  const handleClick = async () => {
    if (window.confirm('Are you sure you want to delete?')) {
      await deleteApiToken()
      onDelete?.(apiToken)
    }
  }

  return (
    <button className="text-red-500" onClick={handleClick}>
      <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" /></svg>
    </button>
  )
}

const MAX = 3

function APITokens() {
  const [listApiTokens, { data }] = useAPI('/api/api_tokens', { autoload: true })
  const [createApiToken] = useAPI('/api/api_tokens', { method: 'POST' })

  const generate = async () => {
    await createApiToken()
    listApiTokens()
  }

  const refresh = () => listApiTokens()

  const apiTokens = data ? data.api_tokens : []
  const isMaxxed = apiTokens.length >= MAX
  const remaining = MAX - apiTokens.length

  return (
    <Card>
      <CardHeader
        title="API Tokens"
        avatar={<svg className="w-8 h-8 text-purple-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4" /></svg>}
        subtitle={`${remaining} out of ${MAX} remaining`}
      />
      {apiTokens.map((apiToken, key) =>
        <div className="px-6 py-3 border-b border-gray-200 flex items-center" key={key}>
          <input
            className="inline-block px-2 py-1 rounded-lg bg-blue-100 text-blue-600 border border-blue-200 text-sm w-64"
            readOnly
            value={apiToken}
            onClick={e => e.target.select()}
          />
          <div className="ml-auto flex items-center">
            <DeleteAPITokenButton
              apiToken={apiToken}
              onDelete={refresh}
            />
          </div>
        </div>
      )}

      <CardBody>
        <Button shadow="md" onClick={generate} disabled={isMaxxed}>Generate API Token</Button>
      </CardBody>
    </Card>
  )
}

function DeveloperDetails() {
  return (
    <Card>
      <CardHeader
        title="Developer details"
        avatar={<svg className="w-8 h-8 text-purple-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 9l3 3-3 3m5 0h3M5 20h14a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" /></svg>}
      />
      <DescriptionList data={developerDetails} />
    </Card>
  )
}

export default function DevelopersScreen() {
  return (
    <>
      <SectionTitle>Developers</SectionTitle>
      <div className="p-6 space-y-4 max-w-lg">
        <DeveloperDetails />
        <APITokens />
      </div>
    </>
  )
}