export function CardHeader({ avatar, action, title, subtitle }) {
  return (
    <div className="flex items-center">
      {avatar && <div className="mr-6">{avatar}</div>}

      <div>
        {title && (
          <div className="font-medium text-lg text-gray-900">{title}</div>
        )}
        {subtitle && <div className="text-sm text-gray-500">{subtitle}</div>}
      </div>

      {action && <div className="ml-auto">{action}</div>}
    </div>
  )
}

export function CardBody({ children }) {
  return <div>{children}</div>
}

export function CardActions({ children }) {
  return (
    <div className="flex items-center justify-end space-x-2">{children}</div>
  )
}

export default function Card({ children }) {
  return (
    <div className="bg-white rounded-lg shadow overflow-hidden py-4 px-5 space-y-4">
      {children}
    </div>
  )
}
