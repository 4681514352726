import { Link } from 'wouter'
import Button from './Button'
import Card, { CardHeader } from './Card'
import Chip from './Chip'
import DataTable from './DataTable'

const columns = {
  id: 'id',
  title: 'Title',
  slug: 'Slug',
}

const values = {
  id: ({ id }) => <Link to={`/social/posts/${id}`}>{id}</Link>,
  slug: ({ slug }) => <Chip>{slug}</Chip>,
  title: ({ title, id }) => (
    <Link to={`/social/posts/${id}`} className="font-medium text-blue-500">
      {title}
    </Link>
  ),
}

const getValue = (record, key) => {
  return values[key]?.(record) || record[key]
}

export default function RoomPosts({ room }) {
  const action = (
    <Button color="blue" to={`/social/rooms/${room.id}/posts/new`}>
      Add post
    </Button>
  )

  return (
    <Card>
      <CardHeader title="Posts" action={action} />
      <DataTable
        columns={Object.keys(columns)}
        data={room.posts}
        getValue={getValue}
      />
    </Card>
  )
}

RoomPosts.fragments = {
  room: `#graphql
    fragment RoomPosts on Room {
      id
      posts {
        id
        title
        slug
      }
    }
  `,
}
