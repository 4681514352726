import Chip from '../components/Chip'
import Money from '../components/Money'
import PaginateCard, { usePaginateCard } from '../components/PaginateCard'

const QUERY = `#graphql
  query LedgerAccountsScreen($paginate: Paginate!) {
    tenant {
      accounts(paginate: $paginate) {
        id
        name
        balance
        type
        currency
        user {
          id
          username
        }
      }
    }
  }
`

const columns = {
  id: 'ID',
  name: 'Name',
  type: 'Type',
  currency: 'Currency',
  balance: 'Balance',
  user: 'User',
}

const typeColors = {
  ASSET: 'green',
  LIABILITY: 'yellow',
}

const values = {
  type: ({ type }) => <Chip color={typeColors[type]}>{type}</Chip>,
  balance: ({ balance }) => <Money amount={balance} format='0,0.000000' />,
  user: ({ user }) => (user ? user.username : '-'),
}

export default function LedgerAccountsScreen() {
  const { paginate, query } = usePaginateCard(QUERY)
  if (!query.data) return null

  return (
    <div className="p-6">
      <PaginateCard
        title="Accounts"
        paginate={paginate}
        query={query}
        data={query.data.tenant.accounts}
        columns={columns}
        values={values}
      />
    </div>
  )
}
