import { Link, useRoute } from 'wouter'
import Avatar from '../components/Avatar'
import Card, { CardHeader } from '../components/Card'
import DescriptionList from '../components/DescriptionList'
import ProductDataTable from '../components/ProductDataTable'
import useWarpgate from '../hooks/useWarpgate'

const QUERY = `#graphql
  query ShopScreen($shopId: ID!) {
    tenant {
      shop(shopId: $shopId) {
        id
        name
        tagline
        description
        vanityName
        websiteUrl
        logoUrl
        coverUrl

        user {
          id
          username
        }

        products {
          ...ProductDataTable
        }
      }
    }
  }

  ${ProductDataTable.fragments.product}
`

export default function ShopScreen() {
  const { shopId } = useRoute()

  const query = useWarpgate(QUERY, { shopId })

  const { __typename, products = [], user = {}, ...shop } = query.data
    ? query.data.tenant.shop
    : {}

  return (
    <div className="p-6 space-y-4">
      <Card>
        <CardHeader
          title={shop.name}
          subtitle={shop.tagline}
          avatar={<Avatar src={shop.logoUrl} />}
        />
        <div className="grid grid-cols-2 gap-2">
          <DescriptionList
            inline
            data={{
              'Shop ID': shop.id,
              'Shop name': shop.name,
              Tagline: shop.tagline,
              Description: shop.description,
              'Vanity name': shop.vanityName,
              'Website URL': <a href={shop.websiteUrl}>{shop.websiteUrl}</a>,
              Owner: <Link to={`/users/${user.id}`}>{user.username}</Link>,
            }}
          />
        </div>
      </Card>

      <Card>
        <CardHeader title="Products" />
        <ProductDataTable data={products} />
      </Card>
    </div>
  )
}
