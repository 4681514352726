import numeral from 'numeral'

import bytesToSize from '../../utils/bytesToSize'
import Card, { CardHeader } from '../../components/Card'
import DescriptionList from '../../components/DescriptionList'
import UsageBar from './UsageBar'
import Button from '../../components/Button'

export default function AccountUsage({ tenant = {} }) {
  const data = {
    'Database rows': (
      <UsageBar
        total={tenant.totalRecords}
        max={tenant.maxRecords}
        getTotal={(num) => numeral(num).format('0,0')}
        getMax={(num) => numeral(num).format('0,0') + ' rows'}
      />
    ),
    'File storage': (
      <UsageBar
        total={tenant.totalStorage}
        max={tenant.maxStorage}
        getTotal={(size) => bytesToSize(size, '')}
        getMax={(size) => bytesToSize(size, 'GB')}
      />
    ),
  }

  return (
    <Card>
      <CardHeader
        title="Account usage"
        avatar={
          <svg
            className="w-6 h-6 text-purple-500"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
            />
          </svg>
        }
        action={<Button to="/billing">Billing</Button>}
      />
      <div className="mb-6">
        <DescriptionList data={data} />
      </div>
    </Card>
  )
}

AccountUsage.fragments = {
  tenant: `#graphql
    fragment AccountUsage on Tenant {
      totalRecords
      maxRecords
      totalStorage
      maxStorage
    }
  `,
}
