import { useEffect, useMemo, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import AppScreen from '../components/AppScreen'
import Button from '../components/Button'
import Card, { CardHeader } from '../components/Card'
import Chip from '../components/Chip'
import RoomFormCard from '../components/RoomFormCard'
import useWarpgate, { useWarpgateLazy } from '../hooks/useWarpgate'
import warpgate from '../utils/warpgate'
import LocalDate from '../components/LocalDate'
import LabelValue from '../components/LabelValue'
import RoomPosts from '../components/RoomPosts'
import DeleteButton from '../components/DeleteButton'
import { Redirect } from 'wouter'

const QUERY = `#graphql
  query RoomScreen($roomId: ID!) {
    tenant {
      room(roomId: $roomId) {
        id
        name
        label
        description
        insertedAt
        updatedAt

        ...RoomPosts
      }
    }
  }

  ${RoomPosts.fragments.room}
`

const DELETE = `#graphql
  mutation DeleteRoom($roomId: ID!) {
    tenant {
      deleteRoom(roomId: $roomId) {
        id
      }
    }
  }
`

const UPDATE = `#graphql
  mutation RoomScreen($roomId: ID!, $input: RoomInput!) {
    tenant {
      updateRoom(roomId: $roomId, input: $input) {
        id
        name
        label
        description
      }
    }
  }
`

function EditRoom({ room, onCompleted }) {
  const [state, setState] = useState({
    loading: false,
    errors: {},
  })

  const update = async (input) => {
    setState({ loading: true })

    const { data, errors } = await warpgate(UPDATE, {
      roomId: room.id,
      input,
    })

    setState({ data, errors })

    if (data) {
      onCompleted(data.tenant.updateRoom)
    }
  }

  return (
    <RoomFormCard
      data={room}
      onSubmit={update}
      errors={state.errors}
      disabled={state.loading}
    />
  )
}

export default function RoomScreen({ params }) {
  const { data } = useWarpgate(QUERY, params)
  const [room, setRoom] = useState({})
  const [editing, setEditing] = useState()
  const [deleteRoom, { data: isDeleted, loading: deleting }] = useWarpgateLazy(
    DELETE,
    params
  )

  const update = (input) => {
    setRoom({ ...room, ...input })
    setEditing(false)
  }

  useEffect(() => {
    if (data) {
      setRoom(data.tenant.room)
    }
  }, [data])

  const action = useMemo(() => {
    if (editing)
      return <Button onClick={() => setEditing(false)}>Cancel</Button>

    return <Button onClick={() => setEditing(true)}>Edit</Button>
  }, [editing])

  const confirmDelete = () => {
    if (!window.confirm('Are you sure you want to delete?')) return
    deleteRoom()
  }

  if (deleting) return null
  if (isDeleted) return <Redirect to="/social/rooms" />

  return (
    <AppScreen title="Room" action={action}>
      <div className="p-6 grid grid-cols-3 gap-6">
        {editing && <EditRoom room={room} onCompleted={update} />}

        {!editing && (
          <div className="space-y-3">
            <Card>
              <CardHeader title={room.label} />
              <ReactMarkdown className="markdown">
                {room.description}
              </ReactMarkdown>
              <div className="border-b border-gray-300" />
              <div className="space-y-1">
                <LabelValue label="Name">
                  <Chip>{room.name}</Chip>
                </LabelValue>

                <LabelValue label="Date">
                  <LocalDate date={room.insertedAt} />
                </LabelValue>

                <LabelValue label="Last updated">
                  <LocalDate date={room.updatedAt} format="fromNow" />
                </LabelValue>
              </div>
            </Card>

            <div className="text-center">
              <DeleteButton onClick={confirmDelete} />
            </div>
          </div>
        )}

        <div className="col-span-2">
          <RoomPosts room={room} />
        </div>
      </div>
    </AppScreen>
  )
}
