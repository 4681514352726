import config from "../config"

function mapErrors(errors) {
  return errors && errors.map(err => err.message)
    .map(err => err.split(': '))
    .reduce((prev, [key, value]) => ({ ...prev, [key]: value }), {})
}

export default async function warpgate(query, variables) {
  const res = await fetch(config.graphQLHost, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${config.token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ query, variables }),
  })

  const { data, errors } = await res.json()

  return { data, errors: mapErrors(errors) }
}