import numeral from 'numeral'

export const toMoney = (n, format = '0,0.00') => {
  return numeral(n).format(format)
}

const symbols = {
  usd: '$',
}

export default function Money({ currency, amount, format }) {
  currency = currency || ''
  let symbol = symbols[currency.toLowerCase()] || currency
  symbol = symbol ? symbol + ' ' : ''
  return symbol + toMoney(amount, format)
}
