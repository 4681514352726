import { Link } from 'wouter'

const userIcon = (
  <svg
    className="w-4 h-4 text-purple-500"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
    />
  </svg>
)

export default function UserLink({ user }) {
  return (
    <Link
      to={`/users/${user.id}`}
      className="inline-flex items-center space-x-2"
    >
      {userIcon}
      <span>{user.username}</span>
    </Link>
  )
}
