import { useCallback } from 'react'
import { Redirect } from 'wouter'
import AppScreen from '../components/AppScreen'
import GroupFormCard from '../components/GroupFormCard'
import useWarpgate, { useWarpgateLazy } from '../hooks/useWarpgate'

const QUERY = `#graphql
  query EditGroupScreen($groupId: ID!) {
    tenant {
      group(groupId: $groupId) {
        id
        name
      }
    }
  }
`

const UPDATE_GROUP = `#graphql
  mutation UpdateGroup($groupId: ID!, $input: GroupInput!) {
    tenant {
      updateGroup(groupId: $groupId, input: $input) {
        id
        name
      }
    }
  }
`

export default function EditGroupScreen({ params }) {
  const [updateGroup, { loading, errors, completed }] = useWarpgateLazy(
    UPDATE_GROUP
  )
  const { data } = useWarpgate(QUERY, params)

  const submit = useCallback(
    (input) => {
      updateGroup({ ...params, input })
    },
    [params, updateGroup]
  )

  if (!data) return null

  if (completed) return <Redirect to="/groups" />

  return (
    <AppScreen title="Edit group">
      <div className="p-6 max-w-lg">
        <GroupFormCard
          data={data.tenant.group}
          onSubmit={submit}
          errors={errors}
          disabled={loading}
          success={completed}
        />
      </div>
    </AppScreen>
  )
}
