import PaginateCard, { usePaginateCard } from '../components/PaginateCard'

const columns = {
  id: 'ID',
  fullname: 'Full name',
  nickname: 'Nick name',
  firstName: 'First name',
  lastName: 'Last name',
  email: 'E-mail',
  emailVerified: 'Verified email',
  user: 'User',
}

const QUERY = `#graphql
  query ProfilesScreen($paginate: Paginate!) {
    tenant {
      id
      profiles(paginate: $paginate) {
        id
        fullname
        nickname
        firstName
        lastName
        email
        emailVerified
        user {
          id
          username
        }
      }
    }
  }
`

const values = {
  user: (profile) => profile.user && profile.user.username,
}

export default function ProfilesScreen() {
  const { paginate, query } = usePaginateCard(QUERY)
  if (!query.data) return null
  return (
    <div className="p-6">
      <PaginateCard
        title="Profiles"
        paginate={paginate}
        query={query}
        data={query.data.tenant.profiles}
        columns={columns}
        values={values}
      />
    </div>
  )
}
