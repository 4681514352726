const isDev = window.location.host.indexOf('localhost') > -1

const apiHost = isDev
  ? 'http://localhost:4000'
  : 'https://graph.warpgate.tech'

const graphQLHost = `${apiHost}/graphql`
const appUrl = isDev
  ? 'http://localhost:3000'
  : 'https://warpgate.tech'

const stripeApiKey = isDev
  ? 'pk_test_51HtQfTIE35Vi7ZikNGpYPyUfHJFygLlaKmprmVx6jUWMo9DplOPdDgWr29UUSQX9C4b46WahWJ3pb6XJKGeh7tsT00YTgPoL4l'
  : 'pk_live_51HtQfTIE35Vi7ZikpVehZPMQ5zyL5HmqUOJQSealxf07ARAVbMEOwKV9vbZLKJyydblCotii8LPtdpeXhDeeLZqR00xMxNAk8F'

const jwtKey = 'wgv-jwt'
const token = window.localStorage.getItem(jwtKey)

const config = {
  stripeApiKey,
  appUrl,
  apiHost,
  graphQLHost,
  isDev,
  jwtKey,
  token,
}

export default config