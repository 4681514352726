import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)

const offset = new Date().getTimezoneOffset()

export const toLocalDate = date => dayjs(date).subtract(offset, 'minutes')

function LocalDate({ date, format = 'MMM D, YYYY h:mm A' }) {
  if (!date) return ''

  const d = toLocalDate(date)

  if (format === 'fromNow') return d.fromNow()

  return d.format(format)
}

export default LocalDate
