import { Redirect } from 'wouter'
import RoomFormCard from '../components/RoomFormCard'
import { useWarpgateLazy } from '../hooks/useWarpgate'

const CREATE_ROOM = `#graphql
  mutation CreateRoom($input: RoomInput!) {
    tenant {
      createRoom(input: $input) {
        id
        name
        label
        description
      }
    }
  }
`

export default function NewRoomScreen() {
  const [createRoom, state] = useWarpgateLazy(CREATE_ROOM)

  const submit = (input) => {
    createRoom({ input })
  }

  if (state.completed && state.data) {
    return <Redirect to={`/social/rooms/${state.data.tenant.createRoom.id}`} />
  }

  return (
    <div className="p-3 max-w-lg">
      <RoomFormCard
        onSubmit={submit}
        disabled={state.loading}
        errors={state.errors || {}}
      />
    </div>
  )
}
