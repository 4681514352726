import { Link } from 'wouter'
import AppScreen from '../components/AppScreen'
import Avatar from '../components/Avatar'
import Button from '../components/Button'
import LocalDate from '../components/LocalDate'
import PaginateCard, { usePaginateCard } from '../components/PaginateCard'

const columns = {
  username: 'Username',
  insertedAt: 'Joined',
  verifiedAt: 'Verified',
  id: 'User ID',
}

const verifiedIcon = (
  <svg
    className="w-5 h-5 text-green-500"
    fill="currentColor"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
      clipRule="evenodd"
    />
  </svg>
)

const values = {
  username: (user) => (
    <Link to={`/users/${user.id}`} className="flex items-center space-x-4">
      <div className="inline-block relative">
        {user.verifiedAt && (
          <div className="absolute bottom-0 -right-2">{verifiedIcon}</div>
        )}
        <Avatar src={user.profile && user.profile.photoUrl} />
      </div>
      <div>{user.username}</div>
    </Link>
  ),
  verifiedAt: (user) => <LocalDate date={user.verifiedAt} />,
  insertedAt: (user) => <LocalDate date={user.insertedAt} />,
  updatedAt: (user) => <LocalDate date={user.updatedAt} />,
}

const QUERY = `#graphql
  query UsersScreen($paginate: Paginate!) {
    tenant {
      id
      users(paginate: $paginate) {
        id
        username
        insertedAt
        verifiedAt

        profile {
          id
          photoUrl
          fullname
        }
      }
    }
  }
`

export default function UsersScreen() {
  const { paginate, query } = usePaginateCard(QUERY)
  if (!query.data) return null

  return (
    <AppScreen
      title="Users"
      action={
        <Button color="blue" to="/users/new">
          New user
        </Button>
      }
    >
      <div className="p-6">
        <PaginateCard
          title="Users"
          paginate={paginate}
          query={query}
          data={query.data.tenant.users}
          columns={columns}
          values={values}
        />
      </div>
    </AppScreen>
  )
}
