import { Fragment } from 'react'
import { Link, Route, Switch } from 'wouter'
import Chip from '../components/Chip'
import SectionTitle from '../components/SectionTitle'
import useWarpgate from '../hooks/useWarpgate'
import ChangePassword from './SettingsScreen/ChangePassword'

const links = {
  'Change password': '/settings/change_password',
}

const dangerLinks = {
  'Delete account': '/settings/account/delete',
}

const colors = {
  'Delete account': 'red',
}

function AppScreen({ title, children }) {
  return (
    <Fragment>
      <SectionTitle>{title}</SectionTitle>
      {children}
    </Fragment>
  )
}

function NavLinks({ items }) {
  return (
    <div className="space-y-3">
      {Object.keys(items).map((label) => (
        <div key={label}>
          <Link
            to={items[label]}
            className={`text-${colors[label] || 'purple'}-500`}
          >
            {label}
          </Link>
        </div>
      ))}
    </div>
  )
}

function Subheader(props) {
  return (
    <div
      className="text-gray-500 text-xs font-bold tracking-wider uppercase"
      {...props}
    />
  )
}

const QUERY = `#graphql
  query SettingsScreen {
    owner {
      id
      email
      verifiedAt
    }
  }
`

export default function SettingsScreen() {
  const { data } = useWarpgate(QUERY)
  const owner = data && data.owner
  if (!owner) return null

  return (
    <AppScreen title="Business settings">
      <div className="p-6 bg-white">
        <div className="space-y-5">
          <div className="space-y-1">
            <Subheader>Email address</Subheader>
            <div className="flex items-center space-x-2">
              <span>{owner.email}</span>
              <Chip color={owner.verifiedAt ? 'green' : 'red'}>
                {owner.verifiedAt ? 'Verified' : 'Not Verified'}
              </Chip>
            </div>
          </div>

          <div className="space-y-1">
            <Subheader>Business account</Subheader>
            <NavLinks items={links} />
          </div>
        </div>

        <div className="mt-20 border-t border-gray-200 pt-6">
          <div className="space-y-3">
            <Subheader>{'⚠️ Danger zone'}</Subheader>
            <NavLinks items={dangerLinks} />
          </div>
        </div>
      </div>
    </AppScreen>
  )
}
