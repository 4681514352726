import AppScreen from '../components/AppScreen'
import Avatar from '../components/Avatar'
import Card, { CardHeader } from '../components/Card'
import DescriptionList from '../components/DescriptionList'
import ListSubheader from '../components/ListSubheader'
import LocalDate from '../components/LocalDate'
import useWarpgate from '../hooks/useWarpgate'

const QUERY = `#graphql
  query UserScreen($userId: ID!) {
    tenant {
      user(userId: $userId) {
        id
        email
        username
        insertedAt
        updatedAt
        verifiedAt

        profile {
          id
          nickname
          photoUrl
          firstName
          lastName
          fullname
          email
        }
      }
    }
  }
`

export default function UserScreen({ params }) {
  const { data } = useWarpgate(QUERY, params)

  const user = data ? data.tenant.user : {}
  const profile = user.profile || {}

  return (
    <AppScreen title="User">
      <div className="p-6 max-w-lg space-y-3">
        <Card>
          <CardHeader
            title={profile.fullname}
            avatar={<Avatar src={profile.photoUrl} />}
          />
          <div className="space-y-4">
            <div className="space-y-2">
              <ListSubheader>User information</ListSubheader>
              <DescriptionList
                data={{
                  'User ID': user.id,
                  Username: user.username,
                  Email: user.email,
                  'Registered date': <LocalDate date={user.insertedAt} />,
                  'Verified date': user.verifiedAt && (
                    <LocalDate date={user.verifiedAt} />
                  ),
                }}
              />
            </div>

            <div className="space-y-2">
              <ListSubheader>Profile</ListSubheader>
              <DescriptionList
                data={{
                  'Profile ID': profile.id,
                  Email: profile.email,
                  'Nick name': profile.nickname,
                  'First name': profile.firstName,
                  'Last name': profile.lastName,
                }}
              />
            </div>
          </div>
        </Card>
      </div>
    </AppScreen>
  )
}
