import cn from 'classnames'
import { useEffect } from 'react'

export default function Modal({ children, open, onClose }) {
  useEffect(() => {
    if (!open) return false

    const onType = (e) => {
      if (e.key === 'Escape') onClose?.()
    }

    window.addEventListener('keyup', onType)

    return () => {
      window.removeEventListener('keyup', onType)
    }
  }, [onClose, open])
  return (
    <div className={cn('fixed inset-0', !open && 'hidden')}>{children}</div>
  )
}
