import { Elements, useStripe } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useEffect, useState } from 'react'
import { useCallback } from 'react'
import Button from '../components/Button'

import Card, { CardBody, CardHeader } from '../components/Card'
import Chip from '../components/Chip'
import DescriptionList from '../components/DescriptionList'
import ListItem from '../components/ListItem'
import ListSubheader from '../components/ListSubheader'
import SectionTitle from '../components/SectionTitle'
import config from '../config'
import useAPI from '../hooks/useAPI'

const stripePromise = loadStripe(config.stripeApiKey)

function PlanButton({
  plan,
  subscribedPlans,
  interval,
  disabled,
  onSubscribe,
  onCancel,
}) {
  const sub = subscribedPlans[plan.id]

  const subscribe = () => onSubscribe?.(plan, interval, plan.prices[interval])

  if (sub && sub.interval !== interval) {
    if (interval === 'month')
      return (
        <Button
          disabled={disabled}
          onClick={subscribe}
          startIcon={
            <svg
              className="w-4 h-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M13 17h8m0 0V9m0 8l-8-8-4 4-6-6"
              />
            </svg>
          }
        >
          Downgrade
        </Button>
      )

    if (interval === 'year')
      return (
        <Button
          color="green"
          variant="flat"
          disabled={disabled}
          onClick={subscribe}
          startIcon={
            <svg
              className="w-4 h-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"
              />
            </svg>
          }
        >
          Upgrade
        </Button>
      )
  }

  if (sub && sub.interval === interval) {
    if (sub.status === 'canceled')
      return (
        <Button
          color="yellow"
          variant="flat"
          disabled={disabled}
          onClick={subscribe}
        >
          Renew
        </Button>
      )

    return (
      <Button
        color="red"
        variant="flat"
        disabled={disabled}
        onClick={() => onCancel?.(sub)}
      >
        Cancel
      </Button>
    )
  }

  return (
    <Button disabled={disabled} onClick={subscribe}>
      Subscribe
    </Button>
  )
}

const SUBTITLES = {
  Striker: <Chip>Level 1</Chip>,
}

function PlanCards() {
  // Hooks
  const [, { data }] = useAPI('/api/plans', { autoload: true })
  const [checkout, checkoutOpts] = useAPI('/api/checkout', { method: 'POST' })
  const stripe = useStripe()
  const [redirecting, setRedirecting] = useState(false)

  // Computed data
  const disabled = checkoutOpts.loading || redirecting
  const sessionId = checkoutOpts.data && checkoutOpts.data.session_id
  const plans = data ? data.plans : []
  const subscriptions = data ? data.subscriptions : []

  const subscribedPlans = subscriptions.reduce((prev, sub) => {
    return { ...prev, [sub.plan_id]: sub }
  }, {})

  const onSubscribe = useCallback(
    (plan, interval, price) => {
      checkout({
        plan_id: plan.id,
        interval,
        price,
      })
    },
    [checkout]
  )

  useEffect(() => {
    if (sessionId && !redirecting) {
      setRedirecting(true)
      stripe.redirectToCheckout({ sessionId })
    }
  }, [redirecting, sessionId, stripe])

  return (
    <div className="space-y-3">
      {plans.map((plan, key) => (
        <Card key={key}>
          <CardHeader title={plan.name} subtitle={SUBTITLES[plan.name]} />
          <div className="space-y-4">
            <div>
              <ListSubheader>Provision</ListSubheader>
              <DescriptionList data={plan.features} />
            </div>
            <div>
              <ListSubheader>Pricing</ListSubheader>

              {Object.keys(plan.prices).map((interval) => (
                <ListItem
                  key={interval}
                  action={
                    <PlanButton
                      plan={plan}
                      interval={interval}
                      subscribedPlans={subscribedPlans}
                      disabled={disabled}
                      onSubscribe={onSubscribe}
                    />
                  }
                  primary={
                    <div>
                      <span className="text-lg">
                        ${Math.round(plan.prices[interval])}
                      </span>
                      <span className="text-sm text-gray-500">/{interval}</span>
                    </div>
                  }
                  secondary={
                    interval === 'year' && (
                      <Chip color="green">Save 2 months</Chip>
                    )
                  }
                />
              ))}
            </div>
          </div>

          <CardBody>
            <div className="text-center">
              <div className="opacity-30 inline-block mx-auto">
                <img
                  width="120"
                  src={'/powered-by-stripe-black.png'}
                  alt="Stripe secure payment"
                />
              </div>
            </div>
          </CardBody>
        </Card>
      ))}
    </div>
  )
}

export default function BillingPlansScreen() {
  return (
    <Elements stripe={stripePromise}>
      <SectionTitle>Subscription Plans</SectionTitle>
      <div className="p-6 max-w-lg">
        <PlanCards />
      </div>
    </Elements>
  )
}
