import { useCallback, useMemo, useRef, useState } from 'react'
import Button from '../components/Button'
import Card, { CardActions, CardBody, CardHeader } from '../components/Card'

import ListItem from '../components/ListItem'
import DropZone from '../components/DropZone'
import bytesToSize from '../utils/bytesToSize'
import FileIcon from '../components/FileIcon'
import useFileUpload from '../hooks/useFileUpload'
import AppScreen from '../components/AppScreen'

function FileListItem({ file, action }) {
  return (
    <ListItem
      icon={<FileIcon type={file.type} />}
      primary={file.name}
      secondary={
        <span className="space-x-2">
          <span>{file.type}</span>
          <span>{bytesToSize(file.size)}</span>
        </span>
      }
      action={action}
    />
  )
}

const checkIcon = <svg className="w-6 h-6 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
const removeIcon = <svg className="w-6 h-6 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
const spinIcon = <svg className="w-6 h-6 text-blue-500 animate-spin" viewBox="0 0 1024 1024" fill="currentColor" height="1em" width="1em" > <path d="M512 1024c-69.1 0-136.2-13.5-199.3-40.2C251.7 958 197 921 150 874c-47-47-84-101.7-109.8-162.7C13.5 648.2 0 581.1 0 512c0-19.9 16.1-36 36-36s36 16.1 36 36c0 59.4 11.6 117 34.6 171.3 22.2 52.4 53.9 99.5 94.3 139.9 40.4 40.4 87.5 72.2 139.9 94.3C395 940.4 452.6 952 512 952c59.4 0 117-11.6 171.3-34.6 52.4-22.2 99.5-53.9 139.9-94.3 40.4-40.4 72.2-87.5 94.3-139.9C940.4 629 952 571.4 952 512c0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.2C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3s-13.5 136.2-40.2 199.3C958 772.3 921 827 874 874c-47 47-101.8 83.9-162.7 109.7-63.1 26.8-130.2 40.3-199.3 40.3z" /> </svg>
const clockIcon = <svg className="w-6 h-6 text-gray-300" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>

function FileAction({ file, uploaded, uploading, disabled, onRemove }) {
  const icon = useMemo(() => {
    if (uploaded) return checkIcon
    if (uploading) return spinIcon
    if (disabled) return clockIcon
    return removeIcon
  }, [disabled, uploaded, uploading])

  return (
    <button
      onClick={() => onRemove(file)}
      className="p-2"
      disabled={uploaded || uploading}
    >{icon}</button>
  )
}

export default function NewFileScreen() {
  // file upload states
  const ref = useRef({
    uploading: {},
    uploaded: {},
  })

  const [uploaded, setUploaded] = useState({})
  const [uploading, setUploading] = useState({})
  const uploader = useFileUpload()

  // Rest
  const [files, setFiles] = useState([])
  const removeFile = file => setFiles(files.filter(f => f !== file))
  const addFiles = newFiles => setFiles([...files, ...newFiles])

  const totalSize = useMemo(() => {
    const total = files.reduce((prev, file) => file.size + prev, 0)
    return bytesToSize(total)
  }, [files])

  const processQueue = useCallback(async (files) => {
    const file = files[0]
    if (!file) return
    ref.current.uploading = { ...ref.current.uploading, [file.name]: true }
    setUploading(ref.current.uploading)

    uploader.submit(file)

    ref.current.uploaded = { ...ref.current.uploaded, [file.name]: true }
    setUploaded(ref.current.uploaded)
    processQueue(files.filter(f => f !== file))
  }, [uploader])

  const startUpload = useCallback(() => processQueue(files), [files, processQueue])

  const isUploading = Object.keys(uploading).length > 0

  const fileWord = files.length ? 'files' : 'file'

  return (
    <AppScreen title="Upload file" action={<Button to="/files">&larr; Back to files</Button>}>
      <div className="p-6 max-w-lg">
        <Card>
          <CardHeader
            title={isUploading ? `Uploading ${fileWord}` : `Upload ${fileWord}`}
            subtitle={files.length > 0 ? totalSize : 'CDN Uploads'}
            avatar={<svg className="w-6 h-6 text-purple-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" /></svg>}
          />
          <div className="divide-y divide-gray-200 overflow-y-auto" style={{ maxHeight: 500 }}>
            {files.map((file, key) =>
              <FileListItem
                {...{ file, key }}
                action={
                  <FileAction
                    file={file}
                    uploaded={uploaded[file.name]}
                    uploading={uploading[file.name]}
                    disabled={isUploading}
                    onRemove={removeFile}
                  />
                }
              />
            )}
          </div>

          {!isUploading &&
            <CardBody>
              <DropZone onFileSelect={addFiles} multiple />
            </CardBody>
          }

          <CardActions>
            <Button
              endIcon={<svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" /></svg>}
              disabled={files.length === 0 || Object.keys(uploading).length > 0}
              onClick={startUpload}
            >Start upload</Button>
          </CardActions>
        </Card>
      </div>
    </AppScreen>
  )
}