import { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'wouter'
import AppScreen from '../components/AppScreen'
import Button from '../components/Button'
import Card, { CardActions, CardHeader } from '../components/Card'
import Select from '../components/Select'
import useWarpgate from '../hooks/useWarpgate'
import warpgate from '../utils/warpgate'

const QUERY = `#graphql
  query ChangeRoomScreen($postId: ID!) {
    tenant {
      rooms {
        id
        name
      }

      post(postId: $postId) {
        id
        room {
          id
          name
        }
      }
    }
  }
`

const UPDATE = `#graphql
  mutation EditPostRoom($roomId: ID!, $postId: ID!) {
    tenant {
      changeRoom(roomId: $roomId, postId: $postId) {
        id
        room {
          id
          name
        }
      }
    }
  }
`

export function ChangeRoomScreen({ params }) {
  const { data } = useWarpgate(QUERY, params)
  const [, setLocation] = useLocation()
  const [room, setRoom] = useState({})

  const roomIds = useMemo(() => {
    if (!data) return {}
    return data.tenant.rooms.reduce((prev, f) => {
      return { ...prev, [f.id]: f }
    }, {})
  }, [data])

  useEffect(() => {
    if (data) {
      setRoom(data.tenant.post.room)
    }
  }, [data])

  const { postId } = params
  const location = `/social/posts/${postId}`

  const update = async () => {
    await warpgate(UPDATE, {
      roomId: room.id,
      postId,
    })

    setLocation(location)
  }

  return (
    <AppScreen action={<Button to={location}>Back</Button>}>
      <div className="p-6 max-w-lg">
        <Card>
          <CardHeader title="Change room" />
          <Select
            label="Select room"
            options={Object.keys(roomIds)}
            getOptionLabel={(id) => roomIds[id].name}
            onChange={(id) => setRoom(roomIds[id])}
            defaultValue={room.id}
          />
          <CardActions>
            <Button
              color="blue"
              type="submit"
              onClick={update}
              disabled={!room.id}
            >
              Submit
            </Button>
          </CardActions>
        </Card>
      </div>
    </AppScreen>
  )
}
