import { useForm } from 'react-hook-form'
import Button from './Button'
import Card, { CardActions, CardHeader } from './Card'
import TextField from './TextField'

export default function RoomFormCard({
  data = {},
  errors,
  disabled,
  onSubmit,
}) {
  const { register, handleSubmit } = useForm()
  errors = errors || {}

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <CardHeader title={data.id ? 'Edit room' : 'New room'} />
        <div className="space-y-3">
          <TextField
            name="label"
            inputRef={register}
            defaultValue={data.label}
            error={errors.label}
            disabled={disabled}
            label="Room label (optional)"
            placeholder="Ex. About us"
            autoComplete="off"
          />

          <TextField
            name="name"
            inputRef={register}
            defaultValue={data.name}
            error={errors.name}
            disabled={disabled}
            label="Unique room ID"
            placeholder="Ex. about-us"
            autoComplete="off"
          />

          <TextField
            name="description"
            inputRef={register}
            defaultValue={data.description}
            error={errors.description}
            disabled={disabled}
            label="Description"
            placeholder="Ex. This is about us"
            multiline
            rows={5}
            autoComplete="off"
          />
        </div>
        <CardActions>
          <Button color="blue" type="submit" disabled={disabled}>
            Submit
          </Button>
        </CardActions>
      </Card>
    </form>
  )
}
