import { useRoute } from 'wouter'
import Button from '../components/Button'
import Card, { CardActions, CardBody, CardHeader } from '../components/Card'
import useAPI from '../hooks/useAPI'

export default function CheckoutSuccessScreen() {
  const { sessionId } = useRoute()
  const [, { success }] = useAPI(`/api/checkout/${sessionId}`, {
    method: 'PUT',
    autoload: true,
  })

  if (success)
    return (
      <div className="p-6 max-w-lg">
        <Card>
          <CardHeader title="Success" avatar={'✨'} />
          <CardBody>
            <div className="text-center">
              {'You have successfully upgraded your plan!'}
            </div>
          </CardBody>
          <CardActions>
            <Button to="/">Dashboard &rarr;</Button>
          </CardActions>
        </Card>
      </div>
    )

  return <div className="p-6">Confirming checkout...</div>
}
