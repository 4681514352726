import cn from 'classnames'

const textColors = {
  gray: 'text-gray-500',
  red: 'text-red-500',
}

const textAligns = {
  left: 'text-left',
  right: 'text-right',
}

function InputHelper({
  align = 'left',
  color = 'gray',
  visible = true,
  ...props
}) {
  const classnames = cn(
    textColors[color],
    textAligns[align],
    visible ? 'h-5 opacity-100' : 'h-0 opacity-0',
    'text-sm overflow-hidden',
    'transition-all duration-200'
  )

  return <div {...props} className={classnames} />
}

function InputAdornment({ error, children }) {
  return (
    <span
      className={cn(
        'px-2 flex items-center justify-center',
        error ? 'text-red-500' : 'text-gray-300'
      )}
    >
      {children}
    </span>
  )
}

function InputField({ rows, multiline, error, inputRef, startIcon, ...props }) {
  const inputClases = cn(
    'w-full',
    'focus:outline-none focus:ring',
    'transition duration-250',
    'rounded-md',
    'leading-tight',
    'py-2 px-3'
  )

  const classes = cn(
    'flex items-center',
    'rounded-md',
    'border',
    'text-gray-800',
    error ? 'border-red-400' : 'border-gray-300'
  )

  const Component = multiline ? 'textarea' : 'input'

  const inputProps = {
    ...props,
    rows: multiline && rows,
    ref: inputRef,
    className: inputClases,
  }

  return (
    <div className={classes}>
      {startIcon && (
        <InputAdornment error={error}> {startIcon} </InputAdornment>
      )}
      <Component {...inputProps} />
    </div>
  )
}

export function InputLabel({ label }) {
  if (!label) return null
  return <div className="text-sm font-medium text-gray-500 mb-1">{label}</div>
}

const textFieldProps = {
  inputRef: Object,
  error: String,
  label: String,
  helper: String,
  multiline: Boolean,
  rows: Number,
  autoComplete: 'string',
  startIcon: null,
}

export default function TextField({
  inputRef,
  error,
  label,
  helper,
  startIcon,
  ...props
} = textFieldProps) {
  return (
    <div className="relative space-y-1">
      <InputLabel label={label} />

      <InputField {...props} {...{ error, inputRef, startIcon }} />

      <InputHelper
        color={error ? 'red' : 'gray'}
        align="right"
        visible={Boolean(error || helper)}
      >
        {error || helper}
      </InputHelper>
    </div>
  )
}
