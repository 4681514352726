import PaginateCard, { usePaginateCard } from '../components/PaginateCard'

const columns = {
  id: 'ID',
  insertedAt: 'Uploaded at',
}

const QUERY = `#graphql
  query AddressesScreen($paginate: Paginate!) {
    tenant {
      id
      addresses(paginate: $paginate) {
        id
        line_1
        line_2
        city
        zipCode
        phone
        alternatePhone
        state
        country
        user {
          id
          username
        }
        insertedAt
      }
    }
  }
`

const values = {
  user: (addr) => addr.user && addr.user.username,
}

export default function AddressesScreen() {
  const { paginate, query } = usePaginateCard(QUERY)
  if (!query.data) return null

  return (
    <div className="p-6">
      <PaginateCard
        title="Addresses"
        paginate={paginate}
        query={query}
        data={query.data.tenant.addresses}
        columns={columns}
        values={values}
      />
    </div>
  )
}
