import { createContext, useContext } from 'react'
import useWarpgate from '../hooks/useWarpgate'

const QUERY = `#graphql
  query OwnerProvider {
    owner {
      id
      username
    }

    tenant {
      id
    }
  }
`

const OwnerContext = createContext()

export default function OwnerProvider({ children }) {
  const { data } = useWarpgate(QUERY)

  return (
    <OwnerContext.Provider value={data || {}}>{children}</OwnerContext.Provider>
  )
}

export const useOwner = () => useContext(OwnerContext)
