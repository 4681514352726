import { Link } from 'wouter'

const icon = (
  <svg
    className="w-4 h-4 text-purple-500"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
    />
  </svg>
)

export default function ShopLink({ shop }) {
  return (
    <Link
      to={`/commerce/shops/${shop.id}`}
      className="inline-flex items-center space-x-2"
    >
      {icon}
      <span>{shop.name}</span>
    </Link>
  )
}
