import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import AppScreen from '../../components/AppScreen'
import Button from '../../components/Button'
import Card, { CardActions, CardBody, CardHeader } from '../../components/Card'
import TextField from '../../components/TextField'
import useAPI from '../../hooks/useAPI'

export default function ChangePassword() {
  const [
    changePassword,
    { data, loading, success, errors },
  ] = useAPI('/api/user/password', { method: 'PUT' })
  const { register, handleSubmit } = useForm()
  const { t } = useTranslation()

  const submit = (body) => changePassword(body)

  return (
    <AppScreen title="Change password">
      <form className="p-6 max-w-lg" onSubmit={handleSubmit(submit)}>
        <Card>
          <CardHeader avatar={'🔐'} title="Update your password" />

          {Boolean(data) && (
            <div className="text-green-700 bg-green-100 px-3 py-2 rounded-lg">
              {'✨ Password updated!'}
            </div>
          )}

          <CardBody>
            <div className="space-y-4">
              <TextField
                label="Current password"
                type="password"
                placeholder="Your current password"
                name="password"
                inputRef={register}
                error={t(errors.password)}
                disabled={loading}
              />

              <TextField
                label="New password"
                type="password"
                placeholder="Type new password"
                name="new_password"
                inputRef={register}
                error={t(errors.new_password)}
                disabled={loading}
              />

              <TextField
                label="Confirm new password"
                type="password"
                placeholder="Type new password again"
                name="new_password_confirmation"
                inputRef={register}
                error={t(errors.new_password_confirmation)}
                disabled={loading}
              />
            </div>
          </CardBody>

          <CardActions>
            <Button
              type="submit"
              color={success ? 'green' : 'blue'}
              size="md"
              disabled={loading}
              shadow="lg"
              startIcon={
                success && (
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                )
              }
            >
              Update password
            </Button>
          </CardActions>
        </Card>
      </form>
    </AppScreen>
  )
}
