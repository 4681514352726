import AppScreen from '../components/AppScreen'
import Button from '../components/Button'
import Card, { CardActions, CardBody, CardHeader } from '../components/Card'
import Chip from '../components/Chip'
import DescriptionList from '../components/DescriptionList'
import ListSubheader from '../components/ListSubheader'
import LocalDate from '../components/LocalDate'
import useAPI from '../hooks/useAPI'

const STATUS_COLORS = {
  active: 'green',
  canceled: 'red',
}

const EMOJIS = {
  year: '🚀',
  month: '⚙️',
}

function EmptySubscription() {
  return (
    <Card>
      <CardHeader title="No subscription found" />
      <CardBody>{'You have not yet subscribed to any plan.'}</CardBody>
      <CardActions>
        <Button to="/billing/plans">Choose plan &rarr;</Button>
      </CardActions>
    </Card>
  )
}

function SubscriptionCard({ subscription: sub }) {
  return (
    <Card>
      <CardHeader
        title={sub.plan.name}
        action={<Button to="/billing/plans">Change plan</Button>}
      />
      <div className="space-y-4 mb-4">
        <div>
          <ListSubheader>Provision</ListSubheader>
          <DescriptionList data={sub.plan.features} />
        </div>
        <div>
          <ListSubheader>Details</ListSubheader>
          <DescriptionList
            data={{
              Interval: `${EMOJIS[sub.interval]} ${sub.interval} `,
              Status: (
                <Chip color={STATUS_COLORS[sub.status]}>{sub.status}</Chip>
              ),
              'Subscription date': (
                <LocalDate date={sub.updated_at} format="fromNow" />
              ),
              'Renewal date': (
                <LocalDate date={sub.expires_at} format="fromNow" />
              ),
            }}
          />
        </div>
      </div>
    </Card>
  )
}

export default function BillingScreen() {
  const [, { loading, data }] = useAPI('/api/subscriptions', { autoload: true })

  const subscriptions = data ? data.subscriptions : []

  const noSub = !loading && subscriptions.length === 0

  const action = <Button to="/billing/plans">Update plan</Button>

  return (
    <AppScreen title="Billing" action={action}>
      <div className="p-6 max-w-lg">
        {noSub && <EmptySubscription />}

        {subscriptions.map((sub) => (
          <SubscriptionCard key={sub.id} subscription={sub} />
        ))}
      </div>
    </AppScreen>
  )
}
