import { Fragment, useEffect, useState } from 'react'
import { Link } from 'wouter'

import Button from '../components/Button'
import Card, { CardActions, CardBody, CardHeader } from '../components/Card'
import Dialog from '../components/Dialog'

import RegisterForm from './RegisterScreen/RegisterForm'
import useAPI from '../hooks/useAPI'
import AppLogo from '../components/AppLogo'
import config from '../config'

function RegisterFormContainer() {
  const [register, registerOpts] = useAPI('/register', { method: 'POST' })
  const [login, loginOpts] = useAPI('/auth', { method: 'POST' })
  const [body, setBody] = useState()

  const submit = (user) => {
    setBody(user)
    register({ user })
  }

  useEffect(() => {
    if (loginOpts.called) return
    if (!registerOpts.success) return
    login(body)
  }, [body, login, loginOpts.called, registerOpts.success])

  useEffect(() => {
    if (!loginOpts.data) return
    window.localStorage.setItem(config.jwtKey, loginOpts.data.token)
    window.location.href = '/home'
  }, [loginOpts.data])

  return (
    <Fragment>
      <div className="text-center mb-6">
        <a href={config.appUrl} className="mx-auto inline-block">
          <AppLogo width={240} />
        </a>
      </div>

      <Card>
        <CardHeader title="Create an account" subtitle="Get started for free" />

        <CardBody>
          {registerOpts.success && (
            <div className="text-center">
              <div className="flex items-center justify-center space-x-3">
                <div className="text-lg">
                  {'✨ You have successfully created your account!'}
                </div>
              </div>
            </div>
          )}

          {!registerOpts.success && (
            <RegisterForm
              onSubmit={submit}
              errors={registerOpts.errors}
              disabled={registerOpts.disabled}
            />
          )}
        </CardBody>

        {registerOpts.success && (
          <CardActions>
            <Button size="md" color="purple" to="/login">
              {'Setup database'} &rarr;
            </Button>
          </CardActions>
        )}
      </Card>

      <div className="text-sm text-center space-x-2 mt-4">
        <span className="text-gray-500">{'Already have an account?'}</span>
        <Link to="/login" className="font-medium text-purple-600">
          Login
        </Link>
      </div>
    </Fragment>
  )
}

export default function RegisterScreen() {
  return (
    <Dialog maxWidth="sm" open>
      <RegisterFormContainer />
    </Dialog>
  )
}
