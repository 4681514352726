import warpgate from '../utils/warpgate'

const PRESIGN_URL = `#graphql
  mutation NewFileScreen($filename: String!) {
    tenant {
      presignUrl(filename: $filename) {
        uploadUrl
        url
      }
    }
  }
`

const CREATE_FILE = `#graphql
  mutation NewFileScreen($input: FileInput!) {
    tenant {
      createFile(input: $input) {
        id
        url
        name
        size
        type
      }
    }
  }
`

export default function useFileUpload() {
  const submit = async (file) => {
    const { data } = await warpgate(PRESIGN_URL, { filename: file.name })

    const { url, uploadUrl } = data.tenant.presignUrl

    await fetch(uploadUrl, {
      method: 'PUT',
      body: file,
    })

    await warpgate(CREATE_FILE, {
      input: {
        name: file.name,
        url,
      },
    })
  }

  return { submit }
}
