import cn from 'classnames'
import { useMemo } from 'react'
import { Link, useRoute } from 'wouter'

function NavListItemIcon({ icon, active }) {
  if (!icon) return null

  const classes = cn(
    'transition-width duration-500',
    'overflow-hidden',
    'transform',
    active ? 'text-purple-500' : 'text-gray-400',
    active ? '-translate-x-2 w-0' : 'w-8'
  )

  return <div className={classes}>{icon}</div>
}

export default function NavListItem({ path, icon, primary }) {
  const [active] = useRoute(path)

  const Component = useMemo(() => {
    if (path) return Link
    return 'div'
  }, [path])

  const classnames = cn(
    'flex items-center',
    'select-none',
    'transition duration-500',
    active ? 'text-purple-800 pointer-events-none' : 'text-gray-600'
  )

  return (
    <Component className={classnames} to={path}>
      <div
        className={cn(
          'transition-all duration-500',
          'overflow-hidden',
          'transform',
          active ? 'w-8' : '-translate-x-2 w-0 opacity-0'
        )}
      >
        <svg
          className="w-5 h-5"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M9 5l7 7-7 7"
          />
        </svg>
      </div>
      <NavListItemIcon {...{ active, icon }} />
      <div className="font-regular">{primary}</div>
    </Component>
  )
}
