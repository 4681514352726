import { Link } from 'wouter'
import ReactMarkdown from 'react-markdown'
import Card from './Card'
import LocalDate from './LocalDate'
import LabelValue from './LabelValue'
import Chip from './Chip'
import Tag from './Tag'

const colors = {
  private: 'gray',
  published: 'green',
}

export default function PostCard({ post }) {
  const { body, room } = post

  return (
    <Card>
      <div className="space-y-2">
        <LabelValue label="Slug">
          <Chip>{post.slug}</Chip>
        </LabelValue>

        <LabelValue label="Posted date">
          <LocalDate date={post.insertedAt} />
        </LabelValue>

        <LabelValue label="Last updated">
          <LocalDate date={post.updatedAt} format="fromNow" />
        </LabelValue>

        <LabelValue label="Room">
          {room && (
            <Link
              to={`/social/rooms/${room.id}`}
              className="font-bold text-blue-500"
            >
              {room.name}
            </Link>
          )}
        </LabelValue>

        <LabelValue label="Status">
          <Chip color={colors[post.status]}>{post.status || 'none'}</Chip>
        </LabelValue>

        <LabelValue label="Tags">
          <div className="space-x-1">
            {(post.tags || []).map((tag, key) => (
              <Tag key={key} radius="xl" color="blue">
                {tag}
              </Tag>
            ))}
          </div>
        </LabelValue>
      </div>

      <div className="border-b border-gray-200"></div>

      <div className="space-y-3">
        <div>
          <div className="text-3xl font-bold">{post.title}</div>
          <div className="text-gray-500 text-sm">
            <LocalDate date={post.insertedAt} format="MMM DD, YYYY" />
          </div>
        </div>

        <div className="text-md text-gray-500">{post.summary}</div>
      </div>

      <ReactMarkdown className="markdown-body">{body}</ReactMarkdown>
    </Card>
  )
}

PostCard.fragments = {
  post: `#graphql
    fragment PostCard on Post {
      id
      title
      slug
      status
      body
      summary
      insertedAt
      updatedAt
      tags

      room {
        id
        name
      }
    }
  `,
}
