import { useState } from 'react'

export default function usePaginate(size = 30) {
  const [pageSize] = useState(size)
  const [sort, setSort] = useState('desc id')
  const [page, setPage] = useState(0)
  const onNext = () => setPage(page + 1)
  const onBack = () => setPage(page - 1)

  return {
    pageSize,
    page,
    sort,
    setSort,
    onNext,
    onBack,
    variables: {
      pageSize, page, sort
    }
  }
}
