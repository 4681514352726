import { Redirect, Route, Switch } from 'wouter'

import AppHeader from './components/AppHeader'
import AppSidebar from './components/AppSidebar'

import AddressesScreen from './screens/AddressesScreen'
import config from './config'
import DevelopersScreen from './screens/DevelopersScreen'
import FilesScreen from './screens/FilesScreen'
import GroupsScreen from './screens/GroupsScreen'
import HomeScreen from './screens/HomeScreen'
import IndexScreen from './screens/IndexScreen'
import LoginScreen from './screens/LoginScreen'
import LogoutScreen from './screens/LogoutScreen'
import NewUserScreen from './screens/NewUserScreen'
import ProfilesScreen from './screens/ProfilesScreen'
import RegisterScreen from './screens/RegisterScreen'
import UsersScreen from './screens/UsersScreen'
import SetupScreen from './screens/SetupScreen'
import NewFileScreen from './screens/NewFileScreen'
import CheckoutSuccessScreen from './screens/CheckoutSuccessScreen'
import BillingScreen from './screens/BillingScreen'
import BillingPlansScreen from './screens/BillingPlansScreen'
import SettingsScreen from './screens/SettingsScreen'
import VerifyScreen from './screens/VerifyScreen'
import PasswordResetScreen from './screens/PasswordResetScreen'
import ClaimPasswordResetScreen from './screens/ClaimPasswordResetScreen'
import LedgerAccountsScreen from './screens/LedgerAccountsScreen'
import LedgerEntriesScreen from './screens/LedgerEntriesScreen'
import UserScreen from './screens/UserScreen'
import ShopsScreen from './screens/ShopsScreen'
import ProductsScreen from './screens/ProductsScreen'
import ShopScreen from './screens/ShopScreen'
import ProductScreen from './screens/ProductScreen'
import OrdersScreen from './screens/OrdersScreen'
import OrderScreen from './screens/OrderScreen'
import InvoicesScreen from './screens/InvoicesScreen'
import InvoiceScreen from './screens/InvoiceScreen'
import PostsScreen from './screens/PostsScreen'
import NewPostScreen from './screens/NewPostScreen'
import PostScreen from './screens/PostScreen'
import OwnerProvider from './context/OwnerProvider'
import { Fragment } from 'react'
import NewRoomScreen from './screens/NewRoomScreen'
import RoomScreen from './screens/RoomScreen'
import RoomsScreen from './screens/RoomsScreen'
import EditPostScreen from './screens/EditPostScreen'
import { ChangeRoomScreen } from './screens/ChangeRoomScreen'
import NewGroupScreen from './screens/NewGroupScreen'
import EditGroupScreen from './screens/EditGroupScreen'
import GroupScreen from './screens/GroupScreen'
import FileScreen from './screens/FileScreen'
import ChangePassword from './screens/SettingsScreen/ChangePassword'
import CommentsScreen from './screens/CommentsScreen'

function DashboardContainer() {
  return (
    <Fragment>
      <div className="w-60 bg-gray-200 fixed bottom-0 left-0 top-0">
        <AppSidebar />
      </div>

      <div className="ml-60 border-l border-gray-200">
        <AppHeader />
        <Switch>
          <Route path="/social/rooms/new" component={NewRoomScreen} />
          <Route
            path="/social/rooms/:roomId/posts/new"
            component={NewPostScreen}
          />
          <Route path="/social/rooms/:roomId" component={RoomScreen} />
          <Route path="/social/rooms" component={RoomsScreen} />

          <Route path="/social/posts/new" component={NewPostScreen} />
          <Route path="/social/posts/:postId/edit" component={EditPostScreen} />
          <Route
            path="/social/posts/:postId/change_room"
            component={ChangeRoomScreen}
          />
          <Route path="/social/posts/:postId" component={PostScreen} />
          <Route path="/social/posts" component={PostsScreen} />

          <Route path="/social/comments" component={CommentsScreen} />

          <Route path="/commerce/shops/:shopId" component={ShopScreen} />
          <Route path="/commerce/shops" component={ShopsScreen} />
          <Route
            path="/commerce/products/:productId"
            component={ProductScreen}
          />
          <Route path="/commerce/products" component={ProductsScreen} />
          <Route path="/commerce/orders/:orderId" component={OrderScreen} />
          <Route path="/commerce/orders" component={OrdersScreen} />
          <Route
            path="/commerce/invoices/:invoiceId"
            component={InvoiceScreen}
          />
          <Route path="/commerce/invoices" component={InvoicesScreen} />

          <Route path="/ledger/entries" component={LedgerEntriesScreen} />
          <Route path="/ledger/accounts" component={LedgerAccountsScreen} />
          <Route path="/addresses" component={AddressesScreen} />
          <Route path="/files/new" component={NewFileScreen} />
          <Route path="/files/:fileId" component={FileScreen} />
          <Route path="/files" component={FilesScreen} />
          <Route path="/groups/new" component={NewGroupScreen} />
          <Route path="/groups/:groupId/edit" component={EditGroupScreen} />
          <Route path="/groups/:groupId" component={GroupScreen} />
          <Route path="/groups" component={GroupsScreen} />
          <Route path="/profiles" component={ProfilesScreen} />
          <Route path="/users/new" component={NewUserScreen} />
          <Route path="/users/:userId" component={UserScreen} />
          <Route path="/users" component={UsersScreen} />
          <Route path="/developers" component={DevelopersScreen} />
          <Route path="/home" component={HomeScreen} />
          <Route path="/setup" component={SetupScreen} />
          <Route path="/logout" component={LogoutScreen} />
          <Route path="/billing/plans" component={BillingPlansScreen} />
          <Route path="/billing" component={BillingScreen} />
          <Route path="/settings/change_password" component={ChangePassword} />
          <Route path="/settings" component={SettingsScreen} />
          <Redirect to="/home" />
        </Switch>
      </div>
    </Fragment>
  )
}

function PreAuthContainer() {
  return (
    <Switch>
      <Route
        path="/password_reset/:code"
        component={ClaimPasswordResetScreen}
      />
      <Route path="/password_reset" component={PasswordResetScreen} />
      <Redirect path="/checkout/cancelled" to="/home" />
      <Route
        path="/checkout/success/:sessionId"
        component={CheckoutSuccessScreen}
      />
      <Redirect path="/checkout/cancelled" to="/home" />
      <Route
        path="/checkout/success/:sessionId"
        component={CheckoutSuccessScreen}
      />
      <Route path="/register" component={RegisterScreen} />
      <Route path="/login" component={LoginScreen} />
      <Route path="/verify/:code" component={VerifyScreen} />
      <Route exact path="/" component={IndexScreen} />
    </Switch>
  )
}

function Authenticated() {
  return (
    <OwnerProvider>
      <DashboardContainer />
    </OwnerProvider>
  )
}

export default function App() {
  return config.token ? <Authenticated /> : <PreAuthContainer />
}
