import { useForm } from 'react-hook-form'
import Button from '../components/Button'
import Card, { CardActions, CardHeader } from '../components/Card'
import TextField from '../components/TextField'
import { useWarpgateLazy } from '../hooks/useWarpgate'

function UserFormCard({ success, errors, loading, onSubmit }) {
  errors = errors || {}
  const { register, handleSubmit } = useForm()

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <CardHeader
          title="Create a user"
          avatar={
            <svg
              className="w-6 h-6 text-purple-500"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"
              />
            </svg>
          }
        />
        <div className="space-y-4">
          <div className="space-y-2">
            <div className="text-xs uppercase font-bold text-gray-400">
              Profile information
            </div>

            <div className="grid grid-cols-2 gap-4">
              <TextField
                name="firstName"
                inputRef={register}
                placeholder="First name"
                error={errors.first_name}
                disabled={loading}
                autoComplete="off"
              />

              <TextField
                name="lastName"
                inputRef={register}
                placeholder="Last name"
                error={errors.last_name}
                disabled={loading}
                autoComplete="off"
              />
            </div>
          </div>

          <div className="space-y-2">
            <div className="text-xs uppercase font-bold text-gray-400">
              Login credentials
            </div>

            <TextField
              name="email"
              inputRef={register}
              placeholder="Email"
              type="email"
              error={errors.email || errors.username}
              disabled={loading}
              autoComplete="off"
            />

            <TextField
              name="password"
              inputRef={register}
              placeholder="Password"
              type="password"
              error={errors.password}
              disabled={loading}
              autoComplete="off"
            />
          </div>
        </div>

        <CardActions>
          <Button
            color={success ? 'green' : 'purple'}
            size="lg"
            fullWidth
            type="submit"
            disabled={loading || success}
          >
            {success ? 'User created' : 'Create user'}
          </Button>
        </CardActions>
      </Card>
    </form>
  )
}

const SIGNUP = `#graphql
  mutation Signup($input: SignupInput!) {
    tenant {
      signup(input: $input) {
        id
        email
        username
        profile {
          id
          firstName
          lastName
          fullname
        }
      }
    }
  }
`

export default function NewUserScreen() {
  const [signup, { data, errors = {}, loading }] = useWarpgateLazy(SIGNUP)
  const success = Boolean(data && data.tenant.signup)

  const submit = (input) => {
    signup({ input })
  }

  return (
    <div className="p-6 max-w-lg">
      <UserFormCard
        onSubmit={submit}
        errors={errors || {}}
        success={success}
        loading={loading}
      />
    </div>
  )
}
