import { useForm } from 'react-hook-form'
import { Link } from 'wouter'
import Button from '../components/Button'
import Card, { CardActions, CardBody, CardHeader } from '../components/Card'
import Dialog from '../components/Dialog'
import TextField from '../components/TextField'
import useAPI from '../hooks/useAPI'

export default function PasswordResetScreen() {
  const { handleSubmit, register } = useForm()
  const [
    requestPasswordReset,
    { success, errors, loading },
  ] = useAPI('/password_reset', { method: 'POST' })

  const submit = (input) => {
    requestPasswordReset(input)
  }

  return (
    <Dialog open>
      <form className="p-6 max-w-lg" onSubmit={handleSubmit(submit)}>
        <Card>
          <CardHeader title="Forgot password" />
          {success && (
            <CardBody>
              <div className="text-center">
                <div className="text-xl font-medium">
                  {'✉️ Password reset link sent!'}
                </div>
                <div className="text-md text-gray-500">
                  {'Please check your email for the next steps.'}
                </div>
              </div>
            </CardBody>
          )}

          {!success && (
            <CardBody>
              <TextField
                name="username"
                label="Enter username"
                placeholder="Username"
                inputRef={register}
                error={errors.username || errors.rate_limit}
                disabled={loading}
              />
            </CardBody>
          )}
          <CardActions>
            <Button
              type="submit"
              color="blue"
              size="md"
              disabled={loading || success}
            >
              Submit
            </Button>
          </CardActions>
        </Card>
        <div className="mt-5 text-center text-sm text-purple-500">
          <Link to="/login">Back to login</Link>
        </div>
      </form>
    </Dialog>
  )
}
