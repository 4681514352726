import cn from 'classnames'

const sizes = {
  sm: 'text-sm',
  md: 'text-md',
}

export default function DescriptionList({ inline, size = 'sm', data = {} }) {
  return (
    <dl className="space-y-2">
      {Object.keys(data).map((key) => (
        <div
          key={key}
          className={cn(
            sizes[size],
            inline ? 'grid grid-cols-3 gap-4' : 'space-y-1'
          )}
        >
          <div className="text-gray-400">{key}</div>
          <div className="col-span-2">{data[key]}</div>
        </div>
      ))}
    </dl>
  )
}
