import { useForm } from 'react-hook-form'
import Button from './Button'
import Card, { CardActions, CardBody, CardHeader } from './Card'
import TextField from './TextField'

export default function GroupFormCard({
  data = {},
  errors,
  disabled,
  onSubmit,
  success,
}) {
  const { handleSubmit, register } = useForm({ defaultValues: data })
  errors = errors || {}

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <CardHeader title={data.id ? 'Update group' : 'New group'} />
        <CardBody>
          <TextField
            name="name"
            inputRef={register}
            placeholder="Name"
            error={errors.name}
            disabled={disabled}
            autoComplete="off"
          />
        </CardBody>
        <CardActions>
          <Button
            color={success ? 'green' : 'blue'}
            type="submit"
            disabled={disabled}
          >
            {success ? 'Success' : 'Submit'}
          </Button>
        </CardActions>
      </Card>
    </form>
  )
}
