import { useMemo } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { useRef } from 'react'
import warpgate from '../utils/warpgate'

const defaultOpts = {
  onCompleted: null,
}

export function useWarpgateLazy(query, variables = defaultOpts) {
  const [state, setState] = useState({
    completed: false,
    loading: false,
    data: null,
    errors: null,
  })

  const refetch = useCallback(
    async (newVariablse = variables) => {
      const { data, errors } = await warpgate(query, newVariablse)
      setState({ data, errors, completed: !errors })
      variables.onCompleted?.(data)
    },
    [query, variables]
  )

  const call = useCallback(
    async (params) => {
      setState({ completed: false, loading: true })
      refetch(params)
    },
    [refetch]
  )

  return [call, { ...state, refetch }]
}

export default function useWarpgate(query, variables = {}) {
  const ref = useRef({
    called: false,
  })

  const json = useMemo(() => {
    return JSON.stringify(variables)
  }, [variables])

  const [call, state] = useWarpgateLazy(query, variables)

  useEffect(() => {
    if (json === ref.current.json) return
    ref.current.json = json
    call()
  }, [call, json])

  return state
}
