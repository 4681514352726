import { useCallback } from 'react'
import cn from 'classnames'

const cancelEvent = e => {
  e.preventDefault()
  e.stopPropagation()
}

const toFiles = list => Array
  .from(list)
  .map((_, i) => list[i])

export default function DropZone({ accept = 'image/*', multiple, onFileSelect }) {
  const onDrop = useCallback(e => {
    cancelEvent(e)
    onFileSelect?.(toFiles(e.dataTransfer.files))
  }, [onFileSelect])

  const classnames = cn(
    'border-2 text-purple-700 rounded-lg p-6 text-center',
    'transition duration-500',
    'border-dashed border-purple-200 bg-white',
    'cursor-pointer',
    'block w-full',
  )

  return (
    <label
      className={classnames}
      onDrop={onDrop}
      onDragOver={cancelEvent}
    >
      <input
        type="file"
        className="hidden"
        {...{ accept, multiple }}
        onChange={e => onFileSelect?.(toFiles(e.target.files))}
      />
      <div className="inline-block mx-auto">
        <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" /></svg>
      </div>
      <div>{'Drop file(s) here'}</div>
    </label>
  )
}
