import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link } from 'wouter'
import AppLogo from '../components/AppLogo'
import Button from '../components/Button'
import Card, { CardBody, CardHeader } from '../components/Card'
import Dialog from '../components/Dialog'
import TextField from '../components/TextField'
import config from '../config'
import useAPI from '../hooks/useAPI'

export default function LoginScreen() {
  const { t } = useTranslation()
  const { register, handleSubmit } = useForm()

  const [login, { errors, disabled, data, success }] = useAPI('/auth', {
    method: 'POST',
  })

  const submit = (input) => login(input)

  useEffect(() => {
    if (data) {
      window.localStorage.setItem(config.jwtKey, data.token)
      window.location.href = '/home'
    }
  }, [data])

  const body = (
    <form onSubmit={handleSubmit(submit)} className="space-y-1">
      <TextField
        placeholder="Username"
        name="username"
        inputRef={register}
        disabled={disabled}
        error={t(errors.username)}
        startIcon={
          <svg
            className="w-6 h-6 text-purple-500"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
            />
          </svg>
        }
        required
      />

      <div>
        <TextField
          placeholder="Password"
          name="password"
          type="password"
          inputRef={register}
          disabled={disabled}
          error={t(errors.password)}
          startIcon={
            <svg
              className="w-6 h-6 text-purple-500"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
              />
            </svg>
          }
          required
        />
      </div>

      <Button
        type="submit"
        fullWidth
        size="lg"
        shadow="lg"
        color="purple"
        disabled={disabled}
      >
        Sign in
      </Button>
    </form>
  )

  return (
    <Dialog open>
      <div className="text-center mb-6">
        <a href={config.appUrl} className="mx-auto inline-block">
          <AppLogo width={240} />
        </a>
      </div>

      <Card>
        <CardHeader title="Log in to your account" />
        <CardBody>{success ? 'Redirecting...' : body}</CardBody>
      </Card>

      <div className="text-sm space-x-1 text-center mt-4">
        <span>{'Not a member yet?'}</span>
        <Link to="/register" className="font-medium text-purple-600">
          Create an account
        </Link>
      </div>

      <div className="text-sm space-x-1 text-center mt-4">
        <Link to="/password_reset" className="font-medium text-purple-600">
          Forgot password
        </Link>
      </div>
    </Dialog>
  )
}
