import cn from 'classnames'

const sizes = {
  sm: 'text-sm',
  md: 'text-md',
}

const colors = {
  gray: 'bg-gray-100 border-gray-200 text-gray-800',
  green: 'bg-green-50 border-green-100 text-green-500',
  red: 'bg-red-50 border-red-100 text-red-500',
  blue: 'bg-blue-100 border-blue-200 text-blue-800',
  yellow: 'bg-yellow-100 border-yellow-200 text-yellow-800',
}

const padding = {
  sm: 'px-1',
  md: 'px-2',
}

const rounded = {
  md: 'rounded-md',
  lg: 'rounded-lg',
  xl: 'rounded-xl',
  full: 'rounded-full',
}

export default function Tag({
  action,
  radius = 'md',
  size = 'md',
  color = 'gray',
  children,
}) {
  const classnames = cn(
    sizes[size],
    colors[color],
    padding[size],
    rounded[radius],
    'border inline-block select-none',
    'inline-flex items-center',
    'space-x-2'
  )

  return (
    <div className={classnames}>
      <span>{children}</span>
      {action}
    </div>
  )
}
