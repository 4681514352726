import { Link } from 'wouter'
import Avatar from '../components/Avatar'
import Chip from '../components/Chip'
import LocalDate from '../components/LocalDate'
import PaginateCard, { usePaginateCard } from '../components/PaginateCard'

const columns = {
  name: 'Name',
  vanityName: 'Vanity name',
  id: 'Shop ID',
  user: 'Owner',
}

const values = {
  name: (shop) => (
    <Link
      className="inline-flex items-center space-x-3"
      to={`/commerce/shops/${shop.id}`}
    >
      <Avatar src={shop.logoUrl} size="sm" />
      <div>
        <div className="font-medium">{shop.name}</div>
        {shop.tagline && (
          <div className="text-sm text-gray-500">{shop.tagline}</div>
        )}
      </div>
    </Link>
  ),
  user: ({ user }) => <Link to={`/users/${user.id}`}>{user.username}</Link>,
  vanityName: ({ vanityName: n }) => n && <Chip>{n}</Chip>,
  insertedAt: (shop) => <LocalDate date={shop.insertedAt} />,
  updatedAt: (shop) => <LocalDate date={shop.updatedAt} />,
}

const QUERY = `#graphql
  query ShopsScreen($paginate: Paginate!) {
    tenant {
      id
      shops(paginate: $paginate) {
        id
        name
        tagline
        description
        vanityName
        websiteUrl
        logoUrl
        coverUrl
        user {
          id
          username
        }
        insertedAt
        updatedAt
      }
    }
  }
`

export default function ShopsScreen() {
  const { paginate, query } = usePaginateCard(QUERY)
  const data = query.data && query.data.tenant.shops

  return (
    <div className="p-6">
      <PaginateCard
        title="Shops"
        paginate={paginate}
        query={query}
        data={data || []}
        columns={columns}
        values={values}
      />
    </div>
  )
}
