import PaginateCard, { usePaginateCard } from '../components/PaginateCard'
import ProductDataTable from '../components/ProductDataTable'

const QUERY = `#graphql
  query ProductsScreen($paginate: Paginate!) {
    tenant {
      id
      products(paginate: $paginate) {
        ...ProductDataTable
      }
    }
  }

  ${ProductDataTable.fragments.product}
`

export default function ProductsScreen() {
  const { paginate, query } = usePaginateCard(QUERY)
  const data = query.data && query.data.tenant.products

  return (
    <div className="p-6">
      <PaginateCard
        title="Products"
        paginate={paginate}
        query={query}
        data={data || []}
        columns={ProductDataTable.columns}
        values={ProductDataTable.values}
      />
    </div>
  )
}
