import AppScreen from '../components/AppScreen'
import Avatar from '../components/Avatar'
import LocalDate from '../components/LocalDate'
import PaginateCard, { usePaginateCard } from '../components/PaginateCard'

const QUERY = `#graphql
  query PostsScreen($paginate: Paginate!) {
    tenant {
      id
      comments(paginate: $paginate) {
        id
        body
        insertedAt
        post {
          id
          title
        }

        user {
          id
          username
        }
      }
    }
  }
`

export default function CommentsScreen() {
  const { paginate, query } = usePaginateCard(QUERY)
  const data = (query.data && query.data.tenant.comments) || []

  return (
    <AppScreen>
      <div className="p-6">
        <PaginateCard
          title="Comments"
          paginate={paginate}
          query={query}
          data={data}
        >
          <div className="divide-y divide-gray-200">
            {data.map((comment, key) => (
              <div className="py-6 flex space-x-6" key={key}>
                <Avatar />
                <div className="space-y-1">
                  <div className="text-xs text-gray-500">
                    <LocalDate date={comment.insertedAt} />
                  </div>
                  {comment.body.split('\n').map((p, key) => (
                    <div key={key}>{p}</div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </PaginateCard>
      </div>
    </AppScreen>
  )
}
