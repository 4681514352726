import { useMemo } from 'react'
import cn from 'classnames'

export default function UsageBar({ total, max, getTotal, getMax }) {
  const perc = max ? total / max : 0

  const backgroundColor = useMemo(() => {
    if (perc >= 0.8) return 'bg-red-500'
    if (perc >= 0.5) return 'bg-yellow-500'
    return 'bg-green-500'
  }, [perc])

  const classnames = cn(
    backgroundColor,
    'rounded-lg h-2',
    'transition-width duration-300'
  )

  const width = perc * 100 + '%'

  return (
    <div className="space-y-1">
      <div className="h-2 bg-gray-200 rounded-lg overflow-hidden">
        <div className={classnames} style={{ width }}></div>
      </div>

      <div className="flex items-center justify-between text-sm">
        <div className="text-gray-500">
          {getTotal?.(total) || total} of {getMax?.(max) || max}
        </div>
        <div className="text-gray-500">{(perc * 100).toFixed(2)}%</div>
      </div>
    </div>
  )
}
